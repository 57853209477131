.linearBG {
}
.firstDiv {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 70%;
}

.ProfileText {
  margin-left: 15px;
  font-weight: 500;
  background: linear-gradient(132.33deg, #d24074 -0.67%, #6518b4 102.54%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: linear-gradient(132.33deg, #d24074 -0.67%, #6518b4 102.54%);
}

.profileLink:hover {
  text-decoration: none;
}
.LinearDiv {
  color: #fff;
  display: flex;
  padding: 7px;
  width: 80%;
  border-radius: 6px;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  background: linear-gradient(132.33deg, #d24074 -0.67%, #6518b4 102.54%);
}
