@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins';
}

a,
a:hover,
a:visited,
a:focus {
  text-decoration: none;
  color: inherit;
}

p {
  margin: 0;
  margin-bottom: 0;
  padding: 0;
}
:root {
  --themeBlue: #1da1f2;
}
.main-view-container {
  margin-top: 15px;
  min-height: 80vh;
  -moz-transform: scale(0.8, 0.8);
  zoom: 0.8;
  zoom: 80%;
  /* padding: 0px 150px; */
}
body {
  background: #fff7f7;
  font-family: 'Poppins';
  /* overflow-x: hidden; */
}
button,
i {
  cursor: pointer;
  outline: none;
}
input {
  outline: none;
}
.container {
  width: 90%;
  margin: auto;
}

.search-wrapper .chip {
  background: #48483d;
}
#root {
  /* overflow-x: hidden; */
  height: 100vh;
}
/* 
select,
select:focus,
input[type='text'],
input[type='number'],
textarea {
  font-size: 16px;
} */

@media screen and (max-width: 900px) {
  .main-view-container {
    -moz-transform: scale(0.8, 0.8);
    zoom: 0.8;
    zoom: 80%;
    padding: 0px 50px;
  }
}
@media screen and (max-width: 480px) {
  .main-view-container {
    -moz-transform: scale(1, 1);
    zoom: 1;
    zoom: 100%;
    padding: 0px;
  }
}
@media screen and (min-width: 2400px) {
  .container {
    width: 50%;
  }
  .main-view-container {
    -moz-transform: scale(1.1, 1.1);
    zoom: 1.1;
    zoom: 110%;
    padding: 10px 350px;
  }
}

.react-multi-carousel-list {
  position: static !important;
}

*:focus-visible {
  outline: none;
}
/* li:hover,
a:hover,
p:hover,
h1:hover,
h2:hover,
h3:hover,
h4:hover,
h5:hover,
h6:hover {
  color: #1da1f2;
} */

.Spinner_SpinnerOverlay__1ubyc {
  height: 60vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Spinner_SpinnerContainer__TSROn {
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 3px solid rgba(195, 195, 195, 0.6);
  border-radius: 50%;
  border-top-color: #636767;
  animation: Spinner_spin__18Ltq 1s ease-in-out infinite;
  -webkit-animation: Spinner_spin__18Ltq 1s ease-in-out infinite;
}

@keyframes Spinner_spin__18Ltq {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes Spinner_spin__18Ltq {
  to {
    -webkit-transform: rotate(360deg);
  }
}

.hideat900 {
  display: flex;
  justify-content: space-between !important;
}

.user-profileInfo {
  flex: 1 1;
  margin-bottom: 10px;
}
.user-profileInfo p {
  margin: 0px auto;
}
.user-profileInfo p:nth-child(1) {
  font-size: 15px;
}
.navbar_logo {
  width: 22px !important;
  margin-right: 10px !important;
  /* fill:var(--themeBlue); */
  /* color: var(--themeBlue) !important; */
}
.showatt900 {
  display: none !important;
}
/* .height900 { */
/* height: 150px; */
/* } */

.dropbtn {
  /* background-color: #04aa6d; */
  color: white;
  /* padding: 16px; */
  font-size: 16px;
  border: none;
}
.avatar {
  align-self: flex-start;
  height: 36px;
  width: 36px;
  /* border-radius: 15px; */
  border-radius: 50%;
  margin-right: 10px;
}
.navText1 {
  color: black;
  /* border-right: 1px solid #ccc; */
  padding-right: 30px;
  padding-left: 20px;
  cursor: pointer;
}

.navText1:last-child {
  border-right: none !important;
}

@media only screen and (min-width: 1440px) {
  .navText1 {
    padding: 2.5rem;
  }
}

.dropdown {
  position: relative;
  display: inline-block;
}
.ant-dropdown {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  position: absolute !important;
  right: 20px;
  left: unset !important;
  top: 68px !important;
}
.ant-dropdown-menu {
  border-radius: 5px;
  /* padding-left: unset !important; */
  list-style: none !important;
  background-color: #fff;
  padding: 0.75rem;
}
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #ffffff;
  /* min-width: 160px; */
  min-width: 750px;
  border-radius: 2px;
  /* margin-top: 5px; */
  border: 1px solid #f1f1f1;
  z-index: 1;
  display: none !important;
}

.dropdown-content a {
  color: #949494;
  padding: 0px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  /* background-color: #f5f5f5; */
  color: #00deff;
}

.dropdown:hover .dropdown-content {
  display: block !important;
}
.dropdown-content:hover {
  display: block !important;
}
.dropdown-content2 {
  display: none;
  position: absolute;
  background-color: #ffffff;
  min-width: 200px;
  /* min-width: 750px; */
  border-radius: 2px;
  /* margin-top: 5px; */
  border: 1px solid #f1f1f1;
  z-index: 1;
  display: none !important;
}

.dropdown-content2 a {
  color: #949494;
  padding: 5px 16px;

  text-decoration: none;
  display: block;
}

.dropdown-content2 a:hover {
  /* background-color: #f5f5f5; */
  color: #00deff;
}

.dropdown:hover .dropdown-content2 {
  display: block !important;
}
.dropdown-content2:hover {
  display: block !important;
}

.dropdown-content1 {
  display: none;
  position: absolute;
  background-color: #ffffff;
  min-width: 200px;
  /* min-width: 750px; */
  border-radius: 2px;
  /* margin-top: 5px; */
  border: 1px solid #f1f1f1;
  z-index: 1;
  display: none !important;
}

.dropdown-content1 a {
  color: #8a8a8a;
  padding: 0px 16px;
  text-decoration: none;
  font-family: PT SANS;
  display: block;
}

.dropdown-content1 a:hover {
  /* background-color: #f5f5f5; */
  color: #00deff;
}
.dropdown:hover .dropdown-content1 {
  display: block !important;
}
.dropdown-content1:hover {
  display: block !important;
}

.dropdown:hover .dropbtn {
  /* background-color: #3e8e41; */
}
.lessthan1010 {
  display: none !important;
}

.morethan1010 {
  display: block !important;
}

.morethan912 {
  display: block !important;
}
.lessthan912 {
  display: none !important;
}

@media screen and (max-width: 912) {
  .morethan912 {
    display: none !important;
  }
  .lessthan912 {
    display: none !important;
  }
}

@media screen and (max-width: 1262px) and (min-width: 1200px) {
  .lessthan1010 {
    display: block !important;
  }
  .morethan1010 {
    display: none !important;
  }
}

@media screen and (max-width: 1010px) {
  .lessthan1010 {
    display: block !important;
  }
  .morethan1010 {
    display: none !important;
  }
}
@media (max-width: 900px) {
  .height900 {
    height: 80px !important;
  }

  .hideat900 {
    display: none !important;
  }
  .showatt900 {
    display: flex !important;
  }
  .overflowat900 {
    overflow-x: auto !important;
    flex-wrap: nowrap !important;
  }
  .dropdown-content {
    display: none !important;
  }
}

.Search_container__TQp30 {
  display: flex;
  flex-direction: column;
  padding: 60px 20px 30px 20px;
  width: 500px;
  margin: auto;
  max-height: 1380px;
  overflow-y: scroll;
}
.Search_container__TQp30::-webkit-scrollbar {
  width: 0;
  background: transparent;
}
.Search_mobileContainer__3sOBZ {
  background-color: unset !important;
  display: flex;
  flex-direction: column;
  padding: 0 20px 30px 20px;
  /* margin-top: 80px; */
  background: #fff;
  max-height: 95%;
  overflow-y: scroll;
  margin-top: 80px;
  width: 100%;
}
.Search_inputWrapper__21wmU {
  position: relative;
  width: 100%;
}
.Search_inputFieldImage__nqEsO {
  cursor: pointer;
  position: absolute;
  z-index: 3;
  right: 20px;
  top: 50%;
}
.Search_inputField__1z95u {
  background: #efefef;
  border-radius: 8px;
  width: 100%;
  font-size: 12px;
  font-weight: 400;
  line-height: 110.5%;
  margin-bottom: 15px;
  height: 45px;
  border: none;
  outline: none;
  padding: 16px 20px;
  font-family: Poppins;
  font-style: normal;
}
.Search_noOfResults__B46IK {
  margin-bottom: 16px;
}
.Search_cardWrapper__17ZNF {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 10px;
  gap: 10px;
  background: white;
  border: 1px solid #fff7f7;
  box-sizing: border-box;
  box-shadow: 2px 4px 8px -4px rgba(108, 96, 255, 0.08), 0px 16px 24px rgba(207, 158, 255, 0.08);
  border-radius: 5px;
  margin-bottom: 15px;
  padding: 15px 13px;
}
.Search_cardTitle__3Kw1A {
  color: #000;
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 10px;
  line-height: 127%;
  text-transform: capitalize;
}
.Search_listDeck__StBhB {
  display: flex;
  flex-direction: column;
  grid-gap: 4px;
  gap: 4px;
  width: 100%;
  overflow: hidden;
}
.Search_listItem__zlWjb {
  position: relative;
  background: #fff;
  border: 0.5px solid #b7b7b7;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  grid-gap: 4px;
  gap: 4px;
  min-height: 70px;
  padding: 5px;
  width: 100%;
}
.Search_listItemImage__3QQTe {
  border-radius: 4px;
  object-fit: cover;
  min-width: 63px;
  min-height: 37px;
  max-width: 63px;
}
.Search_itemTextWrapper__ajjY8 {
  margin: 0 4px 0 7px;
  align-self: center;
  color: #000;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 70%;
}
.Search_itemTitle__1HbO8 {
  color: #000;
  font-weight: 500;
  font-size: 12px;
  align-self: flex-start;
  margin-bottom: 3px;
}
.Search_itemSubtitle__hvSX- {
  color: #000;
  font-weight: 300;
  font-size: 10px;
}
.Search_itemCta__2ALAa {
  padding: 5px;
  width: 50px;
  min-width: 38px;
  border-radius: 3px;
  align-items: center;
  background: linear-gradient(132.33deg, #d24074 -0.67%, #6518b4 102.54%);
  color: #fff;
  text-transform: none;
  font-weight: 600;
  font-size: 9px;
  line-height: 115.5%;
  border: none;
  outline: none;
  align-self: flex-end;
  display: grid;
  place-items: center;
}
.Search_more__3JxNF {
  background: linear-gradient(132.33deg, #d24074 -0.67%, #6518b4 102.54%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-weight: 500;
  line-height: 127%;
  align-self: flex-end;
  cursor: pointer;
}

.linearBG {
}
.firstDiv {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 70%;
}

.ProfileText {
  margin-left: 15px;
  font-weight: 500;
  background: linear-gradient(132.33deg, #d24074 -0.67%, #6518b4 102.54%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: linear-gradient(132.33deg, #d24074 -0.67%, #6518b4 102.54%);
}

.profileLink:hover {
  text-decoration: none;
}
.LinearDiv {
  color: #fff;
  display: flex;
  padding: 7px;
  width: 80%;
  border-radius: 6px;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  background: linear-gradient(132.33deg, #d24074 -0.67%, #6518b4 102.54%);
}

.Navbar_navbar__2KrFT {
  position: absolute;
  height: 75px;
  width: 100vw;
}
.Navbar_navLogo__2js9P {
  display: flex;
  flex: 0.4 1;
}
.Navbar_darkShade__3ca3Z {
  width: 200vw;
  height: 1000vh;
  position: absolute;
  top: -10px;
  background-color: rgba(0, 0, 0, 0.9);
}
.Navbar_navLinksContainer__1nnkb {
  display: flex;
  flex: 1 1;
}
.Navbar_profileWallet__1699m {
  flex: 0.4 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: flex-end;
}

.Navbar_tab_name__1Eye5 {
  text-align: center;
  font-size: 22px !important;
  width: 100% !important;
  font-weight: 700 !important;
  margin-bottom: 30px;
  color: #000000 !important;
  text-transform: capitalize !important;
}
.Navbar_container__1enFG {
  width: 85%;
  height: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Navbar_logo__1YM9H {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.Navbar_menu__2RlPu {
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.9);
  color: #fff;
  list-style: none;
  height: 100vh;
  width: 0;
  overflow: hidden;
  font-size: 1.5rem;
  padding: 50px 0;
  transition: all 0.2s ease-out;
}
.Navbar_menu__2RlPu li {
  margin: 15px 30px;
  cursor: pointer;
}
.Navbar_menu__2RlPu li:hover {
  opacity: 0.2;
}

.Navbar_menu__2RlPu.Navbar_display__3FaR_ {
  width: 230px;
}
.Navbar_menu__2RlPu li a {
  text-decoration: none;
  color: inherit;
}

.Navbar_cross__2zs61 {
  margin: 0 !important;
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 2rem;
  cursor: pointer;
}
.Navbar_hamburger__3td8n {
  cursor: pointer;
}
.Navbar_hamburger__3td8n div {
  height: 3px;
  width: 25px;
  background: #000;
  margin-bottom: 3px;
}

.Navbar_links__1gt0A {
  display: none;
  list-style-type: none;
  font-size: 1.2rem;
  margin-bottom: 0;
  width: 60%;
  justify-content: space-between;
}
.Navbar_links__1gt0A li {
  position: relative;
}
.Navbar_links__1gt0A li::before {
  content: '';
  position: absolute;
  height: 2px;
  width: 100%;
  background: #333;
  bottom: -2px;
  -webkit-transform: scale(0);
          transform: scale(0);
  -webkit-transform-origin: left;
          transform-origin: left;
  transition: all 0.2s ease-out;
}
.Navbar_links__1gt0A li:hover::before {
  -webkit-transform: scale(1);
          transform: scale(1);
}
.Navbar_links__1gt0A li a {
  text-decoration: none;
  color: inherit;
}
.Navbar_active__3b0QJ {
  border-bottom: 1px solid blue !important;
}
@media screen and (min-width: 650px) {
  .Navbar_container__1enFG {
    width: 85%;
  }
  .Navbar_hamburger__3td8n {
    display: none;
  }
  .Navbar_links__1gt0A {
    display: flex;
    font-size: 1.2rem;
    align-items: center;
    cursor: pointer;
  }
}

@media screen and (min-width: 950px) {
  .Navbar_container__1enFG {
    width: 75%;
  }
}

.Navbar_lang__2ZPri {
  margin-right: 1rem;
  background-color: #fdeddb;
  padding: 0.5rem;
  border-radius: 20px;
  box-shadow: 0px 4px 14px -4px #cecdcd;
  cursor: pointer;
}
.Navbar_lang__2ZPri img {
  margin-right: 2px;
  width: 30px;
}

.Navbar_ico__2HbUZ i {
  font-size: 2rem;
  margin-right: 1rem;
  cursor: pointer;
  color: #000;
}

.Navbar_navRight__15DiL {
  display: flex;
  align-items: center;
}
.Navbar_antdDrop__3Y7R2 {
  cursor: pointer;
  font-size: 2rem;
  display: flex;
  align-items: center;
  color: #84855d;
}
.Navbar_antdDrop__3Y7R2 i {
  color: #84855d;
}
.Navbar_logoutModal__4N0Tm {
  pointer-events: auto;
  background: #ffffff;
  border: 1.7375px solid #fff7f7;
  box-shadow: 0px 6.95px 13.9px -6.95px rgba(22, 34, 51, 0.08), 0px 27.8px 41.7px rgba(22, 34, 51, 0.08);
  border-radius: 10px;
}
.Navbar_dropItem__1V3lX {
  font-size: 1.2rem;
  padding: 5px 5px;
  cursor: pointer;
  display: inline-block;
}
.Navbar_LinearText__3E5XE {
  background: linear-gradient(132.33deg, #d24074 -0.67%, #6518b4 102.54%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-weight: 500;
  font-size: 14px !important;
  text-fill-color: transparent;
}
.Navbar_LinearBG__3pr72 {
  background: linear-gradient(132.33deg, #d24074 -0.67%, #6518b4 102.54%);
}
.Navbar_BorderLinear__3cyZ5 {
  border: double 1px transparent !important;
  border-radius: 4px;

  /* margin-bottom: 5px; */
  /* background-image: linear-gradient(white, white),
    linear-gradient(to right, #d24074, #6518b4) !important; */
  background-origin: border-box !important;
  background-clip: padding-box, border-box !important;
}
.Navbar_dropItem__1V3lX i {
  margin-right: 1rem;
}

.Navbar_auth__L2waT {
  background-color: #1fa1d2;
  padding: 0.5rem 1rem;
  color: white;
  border-radius: 6px;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 1rem;
}

.Modal_center__32-6b {
  margin: auto 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: white;
}
.Modal_otp-modal-container__3zyxe {
  background-color: white !important;
}
.Modal_inp__2Sais {
  width: 80%;
  padding: 0.5rem 1rem;
  outline: none;
  border: none;
  border-bottom: 2px solid #d6d3d3;
  transition: 400ms ease;
}
.Modal_inp__2Sais:hover,
.Modal_inp__2Sais:active {
  border-bottom: 2px solid var(--themeBlue);
}
.Modal_inp__2Sais:not(:last-of-type) {
  margin-bottom: 10px;
}
.Modal_google__3xnaL {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  border: 2px solid grey;
  border-radius: 10px;
  cursor: pointer;
}
.Modal_google__3xnaL img {
  margin-right: 1rem;
}
.Modal_otpBtn__1kVVG {
  padding: 0.5rem 1rem;
  border: none;
  outline: none;
  background-color: var(--themeBlue) !important;
  background-color: #1da1f2 !important;
  color: white;
  border-radius: 10px;
}
.Modal_errormssg__2YXFo {
  align-self: flex-start;
  font-weight: 300;
  color: red;
  font-size: 1.8vh;
  margin-left: 10%;
}
.Modal_OtpBox__142Xq {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 31px;
}
.Modal_valid__-YTz3 {
  outline: none !important;
  width: 45px !important;
  height: 45px !important;
  border-radius: 5px !important;
  border: double 1px transparent !important;

  background-image: linear-gradient(white, white), linear-gradient(to right, #d24074, #6518b4) !important;
  background-origin: border-box !important;
  background-clip: padding-box, border-box !important;
}
.Modal_LinearText__10AH0 {
  background: linear-gradient(132.33deg, #d24074 -0.67%, #6518b4 102.54%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  color: mediumvioletred;
  /* color:linear-gradient(132.33deg, #D24074 -0.67%, #6518B4 102.54%); */
}
.Modal_invalid__2uNBS {
  width: 45px !important;
  height: 45px !important;

  border-radius: 5px !important;

  border: double 1px transparent !important;
  background-image: linear-gradient(white, white), linear-gradient(to right, red, red) !important;
  background-origin: border-box !important;
  background-clip: padding-box, border-box !important;
  outline: none;
}
.Modal_container__3NR-c {
  display: flex;
  align-items: center;
  grid-gap: 12px;
  gap: 12px;
  margin-bottom: 12px;
}

.Modal_terms__1Ltg9 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 12px;
  grid-gap: 10px;
  gap: 10px;
  margin: 30px 2px 25px 2px;
}

.Modal_terms__1Ltg9 i {
  color: green;
  font-size: 1.2rem;
  margin-right: 10px;
}
.Modal_cond__2SP82 {
  color: #1da1f2;
}

.Modal_cond__2SP82 > a {
  text-decoration: none;
}

.Modal_pleaseEnterOTP__DGYKa {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 127%;
  color: #212121;
  position: relative;
  margin-bottom: 15px;
}

.Modal_pleaseEnterOTP__DGYKa::before {
  content: '';
  border-top: 1.5px solid;
  border-image-source: linear-gradient(132.33deg, #d24074 -0.67%, #6518b4 102.54%);
  border-image-slice: 1;
  width: 55px;
  height: 0px;
  position: absolute;
  left: 50%;
  top: -10px;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.Modal_invalidOTPText___ZxiW {
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 14px;
  line-height: 127%;
  color: #f00;
  position: relative;
  margin: 0;
}

.Modal_policyQuote__3SzTq {
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 11px;
  line-height: 127%;
  color: #212121;
  margin: 0;
}

.Modal_twoInputWrapper__21Dfs {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 12px;
  gap: 12px;
}

.QuizResultCircle_container__354HR {
  width: 160px;
  height: 160px;
  position: relative;
}

.QuizResultCircle_outer__15How {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 160px;
  width: 160px;
  border-radius: 50%;
  background-color: #c4c4c4;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}

.QuizResultCircle_inner__2dS10 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 120px;
  width: 120px;
  border-radius: 50%;
  background-color: white;
}

.QuizResultCircle_number__wrQ0s {
  font-size: 24px;
  font-weight: 600;
  background: linear-gradient(132.33deg, #d24074 -0.67%, #6518b4 102.54%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: linear-gradient(132.33deg, #d24074 -0.67%, #6518b4 102.54%);
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.QuizResultCircle_svg__1jbhR {
  position: absolute;
  top: 0;
  left: 0;
}


.card-container {
  padding: 10px;
  border: 3px solid #ccc;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  position: relative;
  /* margin-right:10px !important; */
  transition: all 0.5s ease-in-out;
  padding-bottom: 15px;
}
.NotFound {
  margin: auto;
}
.NotFound h5 {
  text-align: center;
}
.card-container:hover {
  cursor: pointer;
}
.small_text {
  width: 100%;
  text-align: center;
  cursor: pointer;
  margin-top: 3px;
  line-height: 2.8ex;
  height: 7ex;
  font-size: 15px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.poll_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
}

.poll_header span {
  font-size: 0.7rem;
  color: rgb(255, 255, 255);
  flex: 1 1;
  text-align: right;
}
.vote-pollButton {
  font-size: 13px !important;
  font-weight: 700 !important;
  background: #2a9d8f !important;
  color: white !important;
  border-radius: 10px !important;
  margin: 10px auto !important;
  transition: all 0.5s ease-in-out;
  text-transform: capitalize !important;
  padding: 2px !important;
}
.view-pollButton {
  font-size: 13px !important;
  font-weight: 700 !important;
  background: var(--themeBlue) !important;
  color: white !important;
  border-radius: 10px !important;
  margin: 10px auto !important;
  transition: all 0.5s ease-in-out;
  text-transform: capitalize !important;
  padding: 2px !important;
}
.view-pollButton:hover,
.vote-pollButton:hover {
  cursor: pointer;
}
.dot {
  position: absolute;
  right: 5px;
  top: 5px;
  height: 10px;
  width: 10px;
  border-radius: 5px;
}
.green-dot {
  background-color: rgb(11, 163, 11);
}
.red-dot {
  background-color: rgb(240, 33, 33);
}

.img-container {
  display: flex;
  justify-content: center;
  width: 30%;
  margin-bottom: 1rem;
  cursor: pointer;
}
.poll-img {
  border-radius: 5px;
  width: 85%;
  max-height: 160px;
  margin-top: 0.2rem;
  box-shadow: 0 2px 8px 0 rgb(0 0 0 / 56%);
  object-fit: cover;
  height: 200px;
  background-size: cover;
  /* background-repeat: no-repeat; */
  /* background-position: 50% 50%; */
}
.poll-tags {
  font-size: 10px !important;
  background-color: var(--themeBlue) !important;
  padding: 5px;
  color: #fff !important;
  font-weight: bold;
  border-radius: 5px;
}
.centerGridStyle {
  display: grid;
  justify-content: center;
  margin: 0px 5px;
}
.buttons-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-bottom: 5px;
}
.button-value {
  margin-top: 1px !important;
}
@media screen and (max-width: 650px) {
  .small_text {
    width: 100%;
    text-align: center;
    cursor: pointer;
    margin-top: 3px;
    line-height: 2.8ex;
    height: 7ex;
    font-size: 14px !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .vote_view-pollButton {
    font-size: 0.8rem !important;
    font-weight: 700 !important;
    background: #2a9d8f !important;
    color: white !important;
    border-radius: 10px !important;
    margin: 10px auto !important;
  }
  .NotFound {
    margin: auto;
    width: 70vw;
    max-width: 250px !important;
  }
  .NotFound h5 {
    text-align: center;
  }
}

.Quiz_topNav__wbLJ0 {
  display: flex;
  justify-content: space-between;
  width: 100%;
  /* align-items: baseline; */
}
.Quiz_LinearK__2LQrC {
  color: red;
  font-size: 200px;
}
.Quiz_PlayModalContainer__XIgvi {
  background: #fff;
  padding: 15px;
}
.Quiz_PlayMoreTitle__3jjw6 {
  font-size: 14px;
}
.Quiz_PlayMoreBottom__3wLLg {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.Quiz_PlayMoreBottom__3wLLg > button {
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 11px;
  border: unset;
  background: linear-gradient(132.33deg, #d24074 -0.67%, #6518b4 102.54%);
  color: #fff;
}
.Quiz_timesUp__2B4fA {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 127%;
  text-align: center;

  color: #ff5757;
  padding: 10px;
  margin-bottom: 10px;
}

.Quiz_quizExitContainer__3v12b {
  background: #fff;
  padding: 22px;
}
.Quiz_quizExitTitle__1Hywx {
  font-size: 18px;
  text-transform: capitalize;
}
.Quiz_quizExitBottom__2fEqL {
  display: flex;
  width: 100%;
  grid-gap: 2%;
  gap: 2%;
  justify-content: flex-end;
  margin-top: 20px;
}

.Quiz_pinkBG__h8SoT {
  background-color: #fff6f6;
  position: absolute;
  top: 20%;
  z-index: -1;
  width: 100%;
  height: 60vh;
}
.Quiz_QuizWrapper__kkSD4 {
  width: 50%;
}
.Quiz_QuizContainer__lNxMl {
  display: flex;
  align-items: center;
  justify-content: center;
}
.Quiz_TitleQuiz__ZpLzc {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
.Quiz_quizExitbtn1__3kGxR,
.Quiz_quizExitbtn2__32kZj {
  border: unset;
  padding: 4px 7px;
  font-size: 12.5px;
  border-radius: 3px;
}
.Quiz_quizExitbtn1__3kGxR {
  background: linear-gradient(132.33deg, #d24074 -0.67%, #6518b4 102.54%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  color: linear-gradient(132.33deg, #d24074 -0.67%, #6518b4 102.54%);
}
.Quiz_quizExitbtn2__32kZj {
  background: linear-gradient(132.33deg, #d24074 -0.67%, #6518b4 102.54%);
  color: #fff;
}
.Quiz_quizbanner__31L3L {
  max-height: 90% !important;
  border-radius: 10px;
  margin-bottom: 10px;
  max-width: 50%;
  box-shadow: 0 2px 6px 0 rgb(0 0 0 / 56%);
}

.Quiz_hintTetMob__3V9Eq {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 127%;
  text-align: center;
  color: #212121;
}
.Quiz_hint__27agV {
  display: grid;
  justify-content: center;
  border: 0.5px solid;

  border-image-source: linear-gradient(132.33deg, #d24074 -0.67%, #6518b4 102.54%);

  border-image-slice: 1;
  border-radius: 2px;
  cursor: pointer;
  padding: 5px;
  text-align: center;
  width: 100px;
  margin-left: 10px;
  height: 50px;
}
.Quiz_mobile_hint__3wcx0 {
  display: flex;
  justify-content: center;
  border: 1px solid #1da1f2;
  border-radius: 10px;
  cursor: pointer;
  padding: 5px;
  margin-bottom: 20px;
  margin-left: 15px;
}
.Quiz_topText__1E7k_ {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 127%;
  text-align: center;
  position: relative;
  color: #212121;
}

.Quiz_topText__1E7k_::after {
  position: absolute;
  bottom: 13px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  content: '';
  border-top: 2px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(132.33deg, #d24074 -0.67%, #6518b4 102.54%);
  width: 37px;
}
.Quiz_hintText__YLfSm {
  color: #989898;
  font-size: 14px;
  width: 100%;
  text-align: center;
}
.Quiz_rules__6ep_q {
  border: 0.5px solid;

  border-image-source: linear-gradient(132.33deg, #d24074 -0.67%, #6518b4 102.54%);

  border-image-slice: 1;
  border-radius: 2px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 3.5px 6.5px;
  cursor: pointer;
  margin-right: 10px;
  justify-self: end;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 127%;

  text-align: center;

  background: linear-gradient(132.33deg, #d24074 -0.67%, #6518b4 102.54%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.Quiz_hintText1__1uqU6 {
  color: #989898;
  font-size: 14px;
  width: 100%;
  text-align: center;
}
.Quiz_image__l2TnW {
  width: 61px;
  height: 61px;
  margin: auto;
  cursor: pointer;
}
.Quiz_quizHeading__1rm41 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 115.5%;
  /* identical to box height, or 28px */

  /* black */

  color: #212121;
}
.Quiz_quizViewBtn__2tTaO {
  background: linear-gradient(132.33deg, #d24074 -0.67%, #6518b4 102.54%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  border: unset;
  line-height: 115.5%;
}
.Quiz_imageWeb__FvZ6X {
  width: 71px;
  height: 71px;
  cursor: pointer;
  margin-bottom: 16px;
}
.Quiz_total_score__1P7aY {
  display: grid;
  justify-content: center;
  border: 1px solid #1da1f2;
  padding: 5px;
  font-size: 12px;
  width: 100px;
  margin-right: 10px;
  height: 50px;
}
.Quiz_mobile_total_score__3iraD {
  display: flex;
  justify-content: center;
  border: 1px solid #1da1f2;
  padding: 5px;
  font-size: 12px;
  margin-right: 15px;
  border-radius: 10px;
  margin-bottom: 20px;
}
.Quiz_marginRight__MBwdd {
  margin-right: 10px;
}

.Quiz_quizCardGrid__-Dtkq {
  /* display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch; */
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 5px 20px;
  gap: 5px 20px;
  padding: 17px 36px;
  background: #ffffff;
  border: 1px solid #fff7f7;
  box-sizing: border-box;
  box-shadow: 0px 4px 8px -4px rgba(22, 34, 51, 0.08), 0px 16px 24px rgba(22, 34, 51, 0.08);
  border-radius: 6px;
  margin: 0 20px;
}
.Quiz_quizCardGridWeb__1lG4y {
  /* display: flex;
  flex-wrap: wrap; */
  justify-content: center;
  align-items: stretch;
  /* gap: 5px 20px; */
  display: grid;
  grid-template-columns: repeat(4, 200px);
  grid-template-rows: repeat(2, 150px);

  grid-column-gap: 18px;
  grid-row-gap: 18px;

  padding: 17px 8px;

  margin: 20px 0 0 0;
  padding-bottom: 50px;
}
.Quiz_pinkBG__h8SoT {
  background-color: #fff6f6;
  position: absolute;
  top: 20%;
  height: 80%;
  z-index: -1;
  width: 100%;
  /* height: 60vh; */
}

.Quiz_mainCategory__3dXBP {
  background: #ffffff;
  border: 1px solid #fff7f7;
  box-sizing: border-box;
  box-shadow: 0px 4px 8px -4px rgba(22, 34, 51, 0.08), 0px 16px 24px rgba(22, 34, 51, 0.08);
  border-radius: 4px;
  width: 101px !important;

  height: 101px;
  padding: 9px 10px 13px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.Quiz_mainCategoryWeb__203sB {
  cursor: pointer;
  background: #ffffff;
  border: 1px solid #fff7f7;
  box-sizing: border-box;
  box-shadow: 0px 4px 8px -4px rgba(22, 34, 51, 0.08), 0px 16px 24px rgba(22, 34, 51, 0.08);
  border-radius: 4px;
  width: 170px !important;
  height: 153px !important;
  padding: 9px 10px 13px;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  border-radius: 19.2308px;
}
.Quiz_levelCategory__3WWxs {
  border: 1px solid #1fa1d2;
  display: grid;
  border-radius: 10px;
  width: 200px;
  padding: 20px;
  justify-content: center;
}

.Quiz_category__2r0E3 {
  border-radius: 10px;
  padding: 40px;
  cursor: pointer;
}
.Quiz_categoryName__2KZHm {
  width: 100%;
  text-align: center;
  margin-top: 8px !important;
  font-size: 14px;
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 8px;
  line-height: 115.5%;
  text-align: center;
  color: #212121;
}
.Quiz_categoryNameWeb__2KjZM {
  width: 100%;
  text-align: center;
  /* margin-top: 8px !important; */
  /* font-size: 13px; */
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 14px;
  line-height: 115.5%;
  text-align: center;
  color: #212121;
}
.Quiz_lock__2esH4 {
  margin-top: 10px;
  border: 0px;
  background-color: #989898;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px;
  font-size: 12px;
  color: #fff;
  border-radius: 10px;
}
.Quiz_play__2qEqb {
  margin-top: 10px;
  border: 0px;
  background-color: #1da1f2;
  padding: 3px;
  font-size: 12px;
  color: #fff;
  border-radius: 10px;
}
.Quiz_levelName__1oH6v {
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 12px;
  line-height: 127%;
  background: linear-gradient(132.33deg, #d24074 -0.67%, #6518b4 102.54%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.Quiz_levelNameLocked__iyVPy {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 127%;

  color: #878787;
}

.Quiz_levelSubtext__1AX6X {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 127%;

  color: #878787;
}

.Quiz_levelSubtextLocked__1VKti {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 127%;
  color: #c4c4c4;
}

.Quiz_width__SjicW {
  width: 250px;
  display: grid;
  justify-content: center;
}
.Quiz_header__1PF1_ {
  background: #fdf9f6;
  color: #535353;
  border-radius: 25px;
  text-align: center;
  padding: 50px 20px;
  /* margin: 30px; */
  font-size: 0.9rem;
  margin-bottom: 30px;
}
.Quiz_pHeading__2sQLa {
  font-size: 40px;
  margin-bottom: 5px;
  color: #000;
  text-transform: uppercase;
}

.Quiz_head__3Qh8W {
  font-size: 34px;
  display: inline-block;
  font-weight: 400 !important;
  /* border-bottom: 4px solid #84855d; */
  padding-bottom: 1rem;
  margin-bottom: 2rem;
}
.Quiz_headCat__36q0J {
  font-size: 16px;
  display: inline-block;
  font-weight: bold !important;
  border-bottom: 4px solid #84855d;
  padding-bottom: 1rem;
  margin-bottom: 2rem;
  margin-left: 55px;
}
.Quiz_gap__1GtaD {
  padding: 0 150px;
}
.Quiz_cards1__37yad {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  margin-left: auto;
  margin-right: auto;
}
.Quiz_card__1r-26 {
  width: 200px;
  height: 150px;
  background-color: #e7e2de;
  border-radius: 15px;
  margin: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 1rem;
}
.Quiz_cardinside__3n_1Y {
  height: 90%;
  width: 90%;
  background-color: #48483d;
  display: flex;
  background-color: #fdeddb;
  border-radius: 15px;
  align-items: center;
  padding: 10px;
  justify-content: center;
  flex-direction: column;
}

.Quiz_name__1sscM {
  width: 190px;
  /* height: 60px; */
  font-weight: normal;
  font-size: 12px;
  /* line-height: 28px; */
  text-align: center;
  /* margin-left: auto; */
  /* margin-right: auto; */
  margin-top: 10px;
}
.Quiz_icon__1uTvv {
  height: 40px;
  width: 40px;
}
.Quiz_box__3zuyX {
  display: flex;
  flex-direction: column;
  /* justify-content: space-around; */
  /* align-items: center; */
  /* height: 900px; */
  width: 600px;
  overflow-x: hidden;
  /* overflow-y: scroll; */
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
}
.Quiz_box1__Fz2U5 {
  display: flex;
  flex-direction: column;
  /* justify-content: space-around; */
  /* align-items: center; */
  /* height: 900px; */
  width: 70%;
  overflow-x: hidden;
  /* overflow-y: scroll; */
  min-height: 65vh;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
}
.Quiz_quizBox__2mxwp {
  background-color: #fcfcfc;
  border: 1px solid #f5f5f5;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  position: relative;
}
.Quiz_actionButton__3QO6A {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Quiz_quizQues__1p8-z {
  /* width: 100px; */
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 5px;
  border-radius: 15px;
  margin: 0px 5px;
  font-size: 12px;
  border: 1px solid #1da1f2;
}
.Quiz_quizIndividualQues__37WLj {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 127%;
  color: #212121;
}

.Quiz_individualQuestion__3TRGa {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 127%;
  text-align: left;
  color: #212121;
  padding-bottom: 10px;
}

.Quiz_quiz__3bm6T {
  display: flex;
  width: 600px;
  /* height: 180px; */
  background: #f0f0f0;
  box-shadow: -4px 4px 20px -6px #fdeddb;
  border-radius: 4px;
  margin: 5px 0;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
  border-radius: 4px;
  cursor: pointer;
}
.Quiz_quiz1__QiG_w {
  display: flex;
  width: 600px;
  cursor: pointer;
  /* height: 180px; */
  background: #fdf9f6;
  box-shadow: -4px 4px 20px -6px #fdeddb;
  border-radius: 4px;
  margin: 5px 0;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
  border-radius: 4px;
}
.Quiz_subbox__cl4Ha {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: center; */
  /* margin: 50px; */
  margin-left: 2rem;
}
.Quiz_subbox1__37Fbu {
  display: flex;
}
.Quiz_playicon__g_bzR {
  /* margin: 80px; */
  /* margin-left: 200px; */
  margin-right: 3rem;
  -webkit-transform: scale(2);
          transform: scale(2);
}
.Quiz_hr1__3pMUs {
  border: 3px solid #84855d;
  width: 80px;
}
.Quiz_qno__3nudB {
  width: 120px;
  height: 50px;
  font-size: 25px;
  background: #84855d;
  border-radius: 50px;
  color: #ffffff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Quiz_content__3cr8q {
  display: flex;
  justify-content: space-between;
  width: 800px;

  margin-top: 50px;
}
.Quiz_sub1__2Z41T {
  width: 540px;
  height: 100px;

  margin-right: 40px;
}
.Quiz_quesc__1kJvF {
  width: 440px;
  background: #f0f0f0;
  box-shadow: -4px 4px 20px -6px #fdeddb;
  border-radius: 4px;
  font-size: 16px;
  margin-bottom: 20px;
  padding: 1rem;
}
.Quiz_ques__r9HKY {
  padding-top: 10px;
  padding-left: 10px;
}
.Quiz_btns__1J9c1 {
  display: flex;
  justify-content: space-between;
}
.Quiz_button__2v8pQ {
  margin: 10px;
  padding: 5px 30px;
  font-size: 14px;
  background-color: #fff;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgb(0 0 0 / 0.2);
}
.Quiz_retry__TeEHu {
  margin: 10px;
  padding: 5px 30px;
  font-size: 14px;
  background-color: #1fa1d2;
  border: 0px;
  border-radius: 5px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Quiz_exit__ZY18j {
  font-size: 16px;
  margin-right: 5px;
}
.Quiz_options1__-EHkT {
  display: flex;
  flex-direction: column;
  height: 600px;
  width: 300px;
  margin-left: 20px;
}
.Quiz_option__N6Ya_ {
  margin-top: 10px !important;
  text-align: center;
  background: #ffffff;
  box-shadow: 0px 4px 8px -4px rgba(22, 34, 51, 0.15), 0px 16px 24px rgba(22, 34, 51, 0.08);
  border-radius: 5px;
  padding: 11px;
  cursor: pointer;
  border: double 1px transparent;
  background-image: linear-gradient(white, white), linear-gradient(to right, #d24074, #6518b4);
  background-origin: border-box;
  background-clip: padding-box, border-box;
}

.Quiz_option__N6Ya_ > span {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 127%;
  background: linear-gradient(132.33deg, #d24074 -0.67%, #6518b4 102.54%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.Quiz_sound__130uL {
  margin-top: 0;
  margin-left: 0;
  justify-self: end;
}
.Quiz_modal1__2IkV6 {
  position: absolute;
  top: 100px;
  right: 200px;
}

.Quiz_level__9vpPZ {
  display: inline-block;
  padding: 0.5rem 0;
  border-bottom: 4px solid #84855d;
  width: -webkit-max-content;
  width: max-content;
  font-size: 20px;
}
.Quiz_questionNumber__naPvZ {
  padding: 0.5rem 1rem;
  background-color: #84855d;
  color: white;
  border-radius: 50px;
}
.Quiz_quizHead__2xwSG {
  margin: 30px 0;
  display: flex;
  align-items: center;
}
.Quiz_timer__1ONUF {
  display: flex;
  align-items: center;
  margin-left: 5rem;
  font-size: 1rem;
}
.Quiz_logo__ZWvkM {
  margin-right: 5px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  /* margin-top: -5px; */
}
.Quiz_question__3mrhM {
  text-align: center;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin-top: 10px;
  font-size: 14px;
}
.Quiz_BuyHints__3waiq {
  /* width: 100%;
  text-align: center; */
  color: var(--themeBlue);
  /* border-bottom: 1px solid #1fa1d2; */
}
.Quiz_RuleDiv__OO9Iq {
  padding: 3px 8px;
  cursor: pointer;
  background-color: transparent;
}
.Quiz_BorderMain__2paiI {
  border: double 1px transparent;
  border-radius: 4px;

  background-image: linear-gradient(white, white), linear-gradient(to right, #d24074, #6518b4);
  background-origin: border-box;
  background-clip: padding-box, border-box;
}
.Quiz_HintSection__21pUB {
  display: flex;
  /* background: transparent; */
  align-items: center;
  cursor: pointer;
  padding: 4px 12px;
}
.Quiz_hinted__1o0Gv {
  display: flex;
  justify-content: space-between;
  background: #ffffff;
  box-shadow: 0px 4px 8px -4px rgba(22, 34, 51, 0.08), 0px 16px 24px rgba(22, 34, 51, 0.08);
  border-radius: 4px;
  margin-top: 10px;
  width: 100%;
  padding: 2px;
}
.Quiz_hintOption__LTdcP {
  display: flex;
  border-radius: 30px;
  align-items: center;
  grid-gap: 1.5px;
  gap: 1.5px;
  padding: 8px 0 8px 10px;
}

.Quiz_hintOptionText__2I_n0 {
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 14px;
  line-height: 127%;

  text-align: center;
  color: #212121;
}
.Quiz_goToWallet__2wv4b {
  margin: 10px;
  padding: 5px 15px;
  font-size: 14px;
  background-color: var(--themeBlue);
  color: rgb(255, 255, 255);
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgb(0 0 0 / 0.2);
  border-radius: 8px;
}
.Quiz_closeInsfBtn__2CPjC {
  margin: 10px;
  padding: 5px 15px;
  font-size: 14px;
  background-color: rgb(243, 239, 239);
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgb(0 0 0 / 0.2);
  border-radius: 8px;
}
.Quiz_hintsModalDailogBox__21XLV {
  margin: auto;
  margin-left: -100px;
}
.Quiz_cancelButton__1SCfY {
  border: 0px;
  width: 100px;
  padding: 5px 0px;
  background-color: #ccc;
  margin: 0px 10px;
  border-radius: 15px;
}
.Quiz_result__1wWRu {
  position: relative;
}
.Quiz_textOnImage__3GIm3 {
  position: absolute;
  width: 200px;
  padding: 5px 15px;
  font-weight: 600;
  border-radius: 15px;
  bottom: 60px;
  background-color: rgba(204, 204, 204, 0.788);
  text-align: center;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.Quiz_resultImage__27q5r {
  width: 100%;
  height: 100%;
}
.Quiz_confirmButton__I2W8P {
  border: 0px;
  width: 100px;
  padding: 5px 0px;
  margin: 0px 10px;
  background-color: #1da1f2;
  color: #fff;
  border-radius: 15px;
}
.Quiz_loadMore__2ymhV {
  padding: 5px 10px;
  border: 1px solid #1da1f2;
  color: #1fa1d2;
  background-color: #fff;
  margin-top: 20px;
  border-radius: 10px;
}
.Quiz_small_info__2dcrZ {
  font-size: 12px !important;
  text-align: center;
}
.Quiz_buy__JB-F4 {
  width: 81px;
  height: 100%;

  background: linear-gradient(132.33deg, #d24074 -0.67%, #6518b4 102.54%);
  box-shadow: 0px 4px 8px -4px rgba(22, 34, 51, 0.08), 0px 16px 24px rgba(22, 34, 51, 0.08);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: none;
}
.Quiz_buy__JB-F4 > span {
  grid-gap: 1.5px !important;
  gap: 1.5px !important;
}
.Quiz_buy__JB-F4 > span > span {
  font-size: 18px;
  line-height: 0;
}
.Quiz_hints__35U8P {
  width: 100%;
  padding: 15px;
  display: flex;
  justify-content: center;
}

.Quiz_val__3iLNR {
  padding: 0.5rem 1rem;
  background-color: #84855d;
  color: white;
  border-radius: 15px;
}
.Quiz_noOf__16jjY {
  display: flex;
  align-items: center;
}
.Quiz_noOf__16jjY i {
  margin-right: 0.5rem;
}

.Quiz_pad__3Vu_P {
  width: 80%;
  margin: 0 auto;
}

.Quiz_down__1jNLv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 30px;
}
.Quiz_downBtn__2YwGd {
  width: 200px;
}

/* ---------------------------------------- */
.Quiz_mobilelevelCategory__3RFxh {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
  width: 100%;
  height: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 8px -4px rgba(22, 34, 51, 0.08), 0px 16px 24px rgba(22, 34, 51, 0.08);
  border-radius: 10px;
  min-height: 60px;
  padding-left: 15px;
}

.Quiz_mobileimage__1YNvx {
  width: 45px;
  height: 41px;
  margin: auto;
  margin-right: 10px;
}
.Quiz_mobileplay__2CIMC {
  border: 0px;
  margin: auto;
  margin-top: 10px;
  background-color: #1da1f2;
  font-size: 12px;
  color: #fff;
  border-radius: 50%;
  padding: 3px;
  align-items: center;
  max-width: 50px !important;
  max-height: 50px !important;
  margin-right: 10px;
}
.Quiz_mobilelock__1N0k1 {
  border: 0px;
  background-color: #989898;
  font-size: 12px;
  align-items: center;
  padding: 3px;
  color: #fff;
  border-radius: 50%;
  max-width: 50px !important;
  max-height: 50px !important;
  margin-right: 10px;
}

.Quiz_cardGridWrapper__zZLvi {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  background-image: url(/static/media/quiz-home-background.853df716.svg);
  background-size: 100% 90%;
  background-repeat: no-repeat;
  margin: 16px auto 50px;
}

.Quiz_hintFilledWrapper__Mad5l {
  background: linear-gradient(132.33deg, #d24074 -0.67%, #6518b4 102.54%);
  border: 0.5px solid #ffffff;
  box-sizing: border-box;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5.5px 10px;
}
.Quiz_hintFilledWrapperWeb__1nOH- {
  background: linear-gradient(132.33deg, #d24074 -0.67%, #6518b4 102.54%);
  border: 0.5px solid #ffffff;
  box-sizing: border-box;
  border-radius: 5px;
  min-width: 55px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7.5px 12px;
  cursor: pointer;
}

.Quiz_hintTextFilled__2j7nc {
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 10px;
  height: 13px;
  /* line-height: 127%; */
  text-align: center;
  color: #ffffff;
}
.Quiz_hintTextFilledWeb__3MxAt {
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 15px;
  line-height: 127%;
  text-align: center;
  color: #ffffff;
}

.Quiz_topHeading__1oJzX {
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 18px !important;
  line-height: 127%;
  text-align: center;
  color: #212121;
}

.Quiz_topNavLevels__2Qq5s {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  width: 100%;
}

.Quiz_topCard__1CmWJ {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 35px;
  gap: 35px;
  padding: 14px 49px;
  background: #ffffff;
  border: 1px solid #fff7f7;
  box-sizing: border-box;
  box-shadow: 0px 4px 8px -4px rgba(22, 34, 51, 0.08), 0px 16px 24px rgba(22, 34, 51, 0.08);
  border-radius: 10px;
  margin: 0 20px 20px;
}
.Quiz_topCardWeb__yhlLa {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 35px;
  gap: 35px;
  width: 44%;
  padding: 30px 59px;
  background: #ffffff;
  border: 1px solid #fff7f7;
  box-sizing: border-box;
  box-shadow: 0px 4px 8px -4px rgba(22, 34, 51, 0.08), 0px 16px 24px rgba(22, 34, 51, 0.08);
  border-radius: 10px;
  margin: 0 20px 20px;
}
.Quiz_CenterContainer__3uYSR {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.Quiz_topCardRight__L8BMi {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.Quiz_WebHeader__2fCLN {
  width: 14%;
}
.Quiz_topCardRightWeb__ZHK0h {
  width: 82%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.Quiz_topCardHeading__304MA {
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 12px;
  line-height: 115.5%;
  background: linear-gradient(132.33deg, #d24074 -0.67%, #6518b4 102.54%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  margin-bottom: 3px;
}
.Quiz_topCardHeadingWeb__1eLP3 {
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 26px;
  line-height: 115.5%;
  background: linear-gradient(132.33deg, #d24074 -0.67%, #6518b4 102.54%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  margin-bottom: 3px;
}
.Quiz_topCardSubheading__3uREf {
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 6px;
  line-height: 115.5%;
  color: #878787;
  margin-bottom: 11px;
}
.Quiz_topCardSubheadingWeb__boK1p {
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 11px;
  line-height: 115.5%;
  color: #878787;
  margin-bottom: 11px;
}

.Quiz_quizLevelBgWrapper__1lL-1 {
  width: 100%;
  height: 100%;
  background-image: url(/static/media/quiz-home-background.853df716.svg);
  background-size: 100% 90%;
  background-repeat: no-repeat;
}
.Quiz_quizLevelBgWrapperWeb__2bSN7 {
  width: 100%;
  height: 100%;
  display: flex;
  margin-top: 1.5%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-size: 100% 90%;
  background-repeat: no-repeat;
}

.Quiz_quizLevelsBottomWrapper__7zL3n {
  background: #ffffff;
  border: 1px solid #fff7f7;
  box-sizing: border-box;
  box-shadow: 0px 4px 8px -4px rgba(22, 34, 51, 0.08), 0px 16px 24px rgba(22, 34, 51, 0.08);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 20px 20px;
  padding: 16px 15px 27px;
}
.Quiz_quizLevelsBottomWrapperWeb__PpJcA {
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 20px 20px;
  padding: 16px 15px 27px;
}

.Quiz_loadMoreBtn__2vHcI {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90px;
  height: 33px;

  background: linear-gradient(132.33deg, #d24074 -0.67%, #6518b4 102.54%);
  border-radius: 3px;

  font-family: 'Poppins';
  font-weight: 600;
  font-size: 12px;
  line-height: 115.5%;
  color: #ffffff;
  outline: none;
  border: none;
  margin: 10px auto 40px;
}

.Quiz_quizIndividualHeading__17KF3 {
  font-family: 'Poppins';
  font-weight: 700;
  font-size: 18px;
  line-height: 127%;
  text-align: center;
  color: #212121;
  margin-top: 30px;
}

.Quiz_quizIndividualBackgroundWrapper__1EoEx {
  background: url(/static/media/quiz-individual-bg.219bb0fb.svg);
  background-repeat: no-repeat;
  background-size: 100% 500px;
  background-position: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-top: -40px;
}
.Quiz_quizIndividualBackgroundWrapperWeb__3Wlhr {
  background: url(/static/media/quiz-individual-bg.219bb0fb.svg);
  background-repeat: no-repeat;
  background-size: 1100px;
  background-position: center;
  background-position: inherit;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 75%;
  margin-top: -40px;
}

.Quiz_quizIndividualCardWrapper__1HTYy {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding: 21px 50px 28px;
  background: #ffffff;
  border: 1px solid #fff7f7;
  box-sizing: border-box;
  box-shadow: 0px 4px 8px -4px rgba(22, 34, 51, 0.08), 0px 16px 24px rgba(22, 34, 51, 0.08);
  border-radius: 6px;
  margin: 0 20px 20px;
}
.Quiz_quizIndividualCardWrapperWeb__3XapF {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 606px;
  padding: 21px 50px 28px;
  background: #ffffff;
  border: 1px solid #fff7f7;
  box-sizing: border-box;
  box-shadow: 0px 4px 8px -4px rgba(22, 34, 51, 0.08), 0px 16px 24px rgba(22, 34, 51, 0.08);
  border-radius: 6px;
  margin: 0 20px 20px;
}

.Quiz_confirmIKCPurchaseTitle__1Q8oe {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 115.5%;
  text-align: center;

  background: linear-gradient(132.33deg, #d24074 -0.67%, #6518b4 102.54%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  margin-top: 5px;
  margin-bottom: 20px;
}

.Quiz_confirmIKCPurchaseSubtitle__2dItb {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 115.5%;
  text-align: center;

  color: #212121;

  margin-bottom: 25px;
}

.Quiz_hintModalAmountText__1NTWs {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 115.5%;

  color: #ffffff;
}

.Quiz_hintModalHeader__22YKq {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-bottom: 15px;
}

.Quiz_hintModalHeaderLeft__a7zrM {
  display: flex;
  flex-direction: column;
}

.Quiz_hintModalHeaderRight__1cHb2 {
  display: flex;
  align-items: center;
  grid-gap: 0;
  gap: 0;
}

.Quiz_hintModalHeaderLeftTitle__2HbzP {
  font-family: 'Poppins';

  font-weight: 700;
  font-size: 14px;
  line-height: 127%;

  color: #212121;
}

.Quiz_hintModalHeaderLeftSubtitle__37Y8J {
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 10px;
  line-height: 127%;

  color: #878787;
}

.Quiz_hintModalHeaderRightText__2bnRr {
  font-family: 'Poppins';
  font-weight: 700;
  font-size: 25.0908px;
  line-height: 115.5%;

  color: #212121;
}

@media (max-width: 750px) {
  .Quiz_marginLeft__zLS09 {
    margin-left: 0px;
  }
  .Quiz_hintOption__LTdcP {
    width: 150px;
  }
  .Quiz_resultImage__27q5r {
    /* width: 300px; */
    height: 300px;
  }
  .Quiz_chooseCat__3rI-C.Quiz_width__SjicW {
    width: 71% !important;
  }
  .Quiz_chooseCat__3rI-C.Quiz_topNav__wbLJ0 {
    justify-content: end;
  }
}
@media (max-width: 650px) {
  .Quiz_mobileplay__2CIMC {
    margin-top: 0 !important;
  }
  .Quiz_closeInsfBtn__2CPjC,
  .Quiz_goToWallet__2wv4b {
    font-size: 12px;
    padding: 10px;
    border-radius: 5px;
  }
  .Quiz_quizbanner__31L3L {
    max-height: 90% !important;
    border-radius: 10px;
    margin-bottom: 0px;
    max-width: 80%;
    box-shadow: none;
  }

  .Quiz_hint__27agV {
    justify-content: center;
    border-radius: 10px;
    cursor: pointer;
    padding: 3px 5px !important;
    width: 30px;
    margin-left: 10px;
    height: 30px;
  }
  .Quiz_hintDB__semK_ {
    opacity: 1;
    transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    width: 70%;
  }
  .Quiz_hintDBWeb__qaOav {
    opacity: 1;
    display: none;
    transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    width: 70%;
  }
  .Quiz_BOX1__3GEPF.Quiz_hint__27agV {
    width: 100px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    justify-self: start;
  }
}

@media screen and (max-width: 500px) {
  .Quiz_button__2v8pQ {
    font-size: 12px !important;
    margin: 5px;
    padding: 5px 10px;
  }

  .Quiz_box1__Fz2U5 {
    width: 100%;
    -webkit-transform: translateY(-40px);
            transform: translateY(-40px);
  }
  .Quiz_question__3mrhM {
    font-size: 12px !important;
  }
}
@media screen and (max-width: 350px) {
  .Quiz_chooseCat__3rI-C.Quiz_topNav__wbLJ0 {
    justify-content: end;
  }
}

.Quiz_ResultContainer__1UHgz {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.Quiz_StatusContainer__15abu {
  position: relative;
  background-color: #fff;
  background: #ffffff;
  border: 1.73751px solid #fff7f7;
  box-shadow: 0px 6.95004px 13.9001px -6.95004px rgba(22, 34, 51, 0.08), 0px 27.8002px 41.7002px rgba(22, 34, 51, 0.08);
  border-radius: 10.4251px;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 27%;
  height: 30%;
  padding: 30px 20px;
}

.Quiz_BottomWrapper__RJdDY {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 80%;
}

.Quiz_BottomBox__37DAZ {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border: 1.73751px solid #fff7f7;
  box-shadow: 0px 6.95004px 13.9001px -6.95004px rgba(22, 34, 51, 0.08), 0px 27.8002px 41.7002px rgba(22, 34, 51, 0.08);
  border-radius: 17.3751px;
  width: 150px;
  height: 133px;
}
.Quiz_pinkSmallBG__3BgCS {
  background: #fff6f6;
  width: 60%;
  height: 40%;
  position: absolute;
  top: 18.6%;
  z-index: -1s;
}
.Quiz_slick-arrow__13SRm {
}
.Quiz_slick-next__3LbSk:before,
.Quiz_slick-prev__qoSFP:before {
  background: linear-gradient(132.33deg, #d24074 -0.67%, #6518b4 102.54%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  width: 40px;
  font-size: 24px;
  text-fill-color: transparent;
}
.Quiz_ExitTryWrapper__A3zeL {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 17%;
  margin-top: 30px;
  margin-bottom: 2%;
}
.Quiz_ExitTryBox__37trN {
  cursor: pointer;
  background: linear-gradient(132.33deg, #d24074 -0.67%, #6518b4 102.54%);
  /* -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  
  text-fill-color: transparent; */
  color: #fff;
  padding: 6px 22px;
  min-width: 110px;
  display: flex;
  min-height: 42px;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}
.Quiz_ResultsScore__1swtM {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  margin-top: 5cpx;
  line-height: 115.5%;
  /* identical to box height, or 28px */

  /* black */

  color: #212121;
}
.Quiz_BottomBoxTitle__2uqnP {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 20px;
  line-height: 115.5%;
  text-align: center;
  /* or 16px */

  /* black */

  color: #212121;
}
.Quiz_BottomStats__2vTpk {
  background: linear-gradient(132.33deg, #d24074 -0.67%, #6518b4 102.54%);
  /* -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  
  text-fill-color: transparent; */
  color: #fff;
  padding: 6px 22px;
  border-radius: 6px;
}

.Quiz_WebWrapper__2FtSa {
  display: grid;
  grid-template-columns: repeat(5, 170px);
  grid-template-rows: repeat(2, 150px);

  grid-column-gap: 20px;
  grid-row-gap: 14px;
}
.Quiz_Webimage__3piiL {
  width: 68px;
  height: 65px;
  margin-top: 10px;
  /* margin: auto; */
  /* margin-right: 10px; */
}
.Quiz_BottomNav__1qP70 {
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
}
.Quiz_BottomLeft__q1Xne {
  width: 80%;
  padding: 2px 0px 12px 12px;
}
.Quiz_BottomTitle__2oRza {
  margin: unset !important;
  font-size: 20px;
  font-weight: 600;
  background: linear-gradient(132.33deg, #d24074 -0.67%, #6518b4 102.54%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  color: linear-gradient(132.33deg, #d24074 -0.67%, #6518b4 102.54%);
}
.Quiz_BottomTitleRes__2Wtzu {
  margin: unset !important;
  font-size: 24px;
  font-weight: 600;
  background: linear-gradient(132.33deg, #d24074 -0.67%, #6518b4 102.54%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  color: linear-gradient(132.33deg, #d24074 -0.67%, #6518b4 102.54%);
}
.Quiz_BottomSubTitle__8-hpJ {
  font-size: 11px;
  color: '#878787';
}
.Quiz_BottomRight__2htZu {
  height: 100%;
  width: 30%;
}
.Quiz_WebContainer__PbuS7 {
  cursor: pointer;
  border: 1.12371px solid #fff7f7;
  box-shadow: 0px 4.49485px 8.98969px -4.49485px rgba(22, 34, 51, 0.08), 0px 17.9794px 26.9691px rgba(22, 34, 51, 0.08);
  border-radius: 4.49485px;
  background-color: #fff;
  display: flex;
  border-radius: 6px;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}

