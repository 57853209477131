.navbar {
  position: absolute;
  height: 75px;
  width: 100vw;
}
.navLogo {
  display: flex;
  flex: 0.4;
}
.darkShade {
  width: 200vw;
  height: 1000vh;
  position: absolute;
  top: -10px;
  background-color: rgba(0, 0, 0, 0.9);
}
.navLinksContainer {
  display: flex;
  flex: 1;
}
.profileWallet {
  flex: 0.4;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: flex-end;
}

.tab_name {
  text-align: center;
  font-size: 22px !important;
  width: 100% !important;
  font-weight: 700 !important;
  margin-bottom: 30px;
  color: #000000 !important;
  text-transform: capitalize !important;
}
.container {
  width: 85%;
  height: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.menu {
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.9);
  color: #fff;
  list-style: none;
  height: 100vh;
  width: 0;
  overflow: hidden;
  font-size: 1.5rem;
  padding: 50px 0;
  transition: all 0.2s ease-out;
}
.menu li {
  margin: 15px 30px;
  cursor: pointer;
}
.menu li:hover {
  opacity: 0.2;
}

.menu.display {
  width: 230px;
}
.menu li a {
  text-decoration: none;
  color: inherit;
}

.cross {
  margin: 0 !important;
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 2rem;
  cursor: pointer;
}
.hamburger {
  cursor: pointer;
}
.hamburger div {
  height: 3px;
  width: 25px;
  background: #000;
  margin-bottom: 3px;
}

.links {
  display: none;
  list-style-type: none;
  font-size: 1.2rem;
  margin-bottom: 0;
  width: 60%;
  justify-content: space-between;
}
.links li {
  position: relative;
}
.links li::before {
  content: '';
  position: absolute;
  height: 2px;
  width: 100%;
  background: #333;
  bottom: -2px;
  transform: scale(0);
  transform-origin: left;
  transition: all 0.2s ease-out;
}
.links li:hover::before {
  transform: scale(1);
}
.links li a {
  text-decoration: none;
  color: inherit;
}
.active {
  border-bottom: 1px solid blue !important;
}
@media screen and (min-width: 650px) {
  .container {
    width: 85%;
  }
  .hamburger {
    display: none;
  }
  .links {
    display: flex;
    font-size: 1.2rem;
    align-items: center;
    cursor: pointer;
  }
}

@media screen and (min-width: 950px) {
  .container {
    width: 75%;
  }
}

.lang {
  margin-right: 1rem;
  background-color: #fdeddb;
  padding: 0.5rem;
  border-radius: 20px;
  box-shadow: 0px 4px 14px -4px #cecdcd;
  cursor: pointer;
}
.lang img {
  margin-right: 2px;
  width: 30px;
}

.ico i {
  font-size: 2rem;
  margin-right: 1rem;
  cursor: pointer;
  color: #000;
}

.navRight {
  display: flex;
  align-items: center;
}
.antdDrop {
  cursor: pointer;
  font-size: 2rem;
  display: flex;
  align-items: center;
  color: #84855d;
}
.antdDrop i {
  color: #84855d;
}
.logoutModal {
  pointer-events: auto;
  background: #ffffff;
  border: 1.7375px solid #fff7f7;
  box-shadow: 0px 6.95px 13.9px -6.95px rgba(22, 34, 51, 0.08), 0px 27.8px 41.7px rgba(22, 34, 51, 0.08);
  border-radius: 10px;
}
.dropItem {
  font-size: 1.2rem;
  padding: 5px 5px;
  cursor: pointer;
  display: inline-block;
}
.LinearText {
  background: linear-gradient(132.33deg, #d24074 -0.67%, #6518b4 102.54%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-weight: 500;
  font-size: 14px !important;
  text-fill-color: transparent;
}
.LinearBG {
  background: linear-gradient(132.33deg, #d24074 -0.67%, #6518b4 102.54%);
}
.BorderLinear {
  border: double 1px transparent !important;
  border-radius: 4px;

  /* margin-bottom: 5px; */
  /* background-image: linear-gradient(white, white),
    linear-gradient(to right, #d24074, #6518b4) !important; */
  background-origin: border-box !important;
  background-clip: padding-box, border-box !important;
}
.dropItem i {
  margin-right: 1rem;
}

.auth {
  background-color: #1fa1d2;
  padding: 0.5rem 1rem;
  color: white;
  border-radius: 6px;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 1rem;
}
