.container {
  width: 160px;
  height: 160px;
  position: relative;
}

.outer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 160px;
  width: 160px;
  border-radius: 50%;
  background-color: #c4c4c4;
  transform: rotate(-90deg);
}

.inner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 120px;
  width: 120px;
  border-radius: 50%;
  background-color: white;
}

.number {
  font-size: 24px;
  font-weight: 600;
  background: linear-gradient(132.33deg, #d24074 -0.67%, #6518b4 102.54%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: linear-gradient(132.33deg, #d24074 -0.67%, #6518b4 102.54%);
  transform: rotate(90deg);
}

.svg {
  position: absolute;
  top: 0;
  left: 0;
}

