.card-container {
  padding: 10px;
  border: 3px solid #ccc;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  position: relative;
  /* margin-right:10px !important; */
  transition: all 0.5s ease-in-out;
  padding-bottom: 15px;
}
.NotFound {
  margin: auto;
}
.NotFound h5 {
  text-align: center;
}
.card-container:hover {
  cursor: pointer;
}
.small_text {
  width: 100%;
  text-align: center;
  cursor: pointer;
  margin-top: 3px;
  line-height: 2.8ex;
  height: 7ex;
  font-size: 15px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.poll_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
}

.poll_header span {
  font-size: 0.7rem;
  color: rgb(255, 255, 255);
  flex: 1;
  text-align: right;
}
.vote-pollButton {
  font-size: 13px !important;
  font-weight: 700 !important;
  background: #2a9d8f !important;
  color: white !important;
  border-radius: 10px !important;
  margin: 10px auto !important;
  transition: all 0.5s ease-in-out;
  text-transform: capitalize !important;
  padding: 2px !important;
}
.view-pollButton {
  font-size: 13px !important;
  font-weight: 700 !important;
  background: var(--themeBlue) !important;
  color: white !important;
  border-radius: 10px !important;
  margin: 10px auto !important;
  transition: all 0.5s ease-in-out;
  text-transform: capitalize !important;
  padding: 2px !important;
}
.view-pollButton:hover,
.vote-pollButton:hover {
  cursor: pointer;
}
.dot {
  position: absolute;
  right: 5px;
  top: 5px;
  height: 10px;
  width: 10px;
  border-radius: 5px;
}
.green-dot {
  background-color: rgb(11, 163, 11);
}
.red-dot {
  background-color: rgb(240, 33, 33);
}

.img-container {
  display: flex;
  justify-content: center;
  width: 30%;
  margin-bottom: 1rem;
  cursor: pointer;
}
.poll-img {
  border-radius: 5px;
  width: 85%;
  max-height: 160px;
  margin-top: 0.2rem;
  box-shadow: 0 2px 8px 0 rgb(0 0 0 / 56%);
  object-fit: cover;
  height: 200px;
  background-size: cover;
  /* background-repeat: no-repeat; */
  /* background-position: 50% 50%; */
}
.poll-tags {
  font-size: 10px !important;
  background-color: var(--themeBlue) !important;
  padding: 5px;
  color: #fff !important;
  font-weight: bold;
  border-radius: 5px;
}
.centerGridStyle {
  display: grid;
  justify-content: center;
  margin: 0px 5px;
}
.buttons-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-bottom: 5px;
}
.button-value {
  margin-top: 1px !important;
}
@media screen and (max-width: 650px) {
  .small_text {
    width: 100%;
    text-align: center;
    cursor: pointer;
    margin-top: 3px;
    line-height: 2.8ex;
    height: 7ex;
    font-size: 14px !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .vote_view-pollButton {
    font-size: 0.8rem !important;
    font-weight: 700 !important;
    background: #2a9d8f !important;
    color: white !important;
    border-radius: 10px !important;
    margin: 10px auto !important;
  }
  .NotFound {
    margin: auto;
    width: 70vw;
    max-width: 250px !important;
  }
  .NotFound h5 {
    text-align: center;
  }
}
