.topNav {
  display: flex;
  justify-content: space-between;
  width: 100%;
  /* align-items: baseline; */
}
.LinearK {
  color: red;
  font-size: 200px;
}
.PlayModalContainer {
  background: #fff;
  padding: 15px;
}
.PlayMoreTitle {
  font-size: 14px;
}
.PlayMoreBottom {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.PlayMoreBottom > button {
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 11px;
  border: unset;
  background: linear-gradient(132.33deg, #d24074 -0.67%, #6518b4 102.54%);
  color: #fff;
}
.timesUp {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 127%;
  text-align: center;

  color: #ff5757;
  padding: 10px;
  margin-bottom: 10px;
}

.quizExitContainer {
  background: #fff;
  padding: 22px;
}
.quizExitTitle {
  font-size: 18px;
  text-transform: capitalize;
}
.quizExitBottom {
  display: flex;
  width: 100%;
  gap: 2%;
  justify-content: flex-end;
  margin-top: 20px;
}

.pinkBG {
  background-color: #fff6f6;
  position: absolute;
  top: 20%;
  z-index: -1;
  width: 100%;
  height: 60vh;
}
.QuizWrapper {
  width: 50%;
}
.QuizContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.TitleQuiz {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
.quizExitbtn1,
.quizExitbtn2 {
  border: unset;
  padding: 4px 7px;
  font-size: 12.5px;
  border-radius: 3px;
}
.quizExitbtn1 {
  background: linear-gradient(132.33deg, #d24074 -0.67%, #6518b4 102.54%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  color: linear-gradient(132.33deg, #d24074 -0.67%, #6518b4 102.54%);
}
.quizExitbtn2 {
  background: linear-gradient(132.33deg, #d24074 -0.67%, #6518b4 102.54%);
  color: #fff;
}
.quizbanner {
  max-height: 90% !important;
  border-radius: 10px;
  margin-bottom: 10px;
  max-width: 50%;
  box-shadow: 0 2px 6px 0 rgb(0 0 0 / 56%);
}

.hintTetMob {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 127%;
  text-align: center;
  color: #212121;
}
.hint {
  display: grid;
  justify-content: center;
  border: 0.5px solid;

  border-image-source: linear-gradient(132.33deg, #d24074 -0.67%, #6518b4 102.54%);

  border-image-slice: 1;
  border-radius: 2px;
  cursor: pointer;
  padding: 5px;
  text-align: center;
  width: 100px;
  margin-left: 10px;
  height: 50px;
}
.mobile_hint {
  display: flex;
  justify-content: center;
  border: 1px solid #1da1f2;
  border-radius: 10px;
  cursor: pointer;
  padding: 5px;
  margin-bottom: 20px;
  margin-left: 15px;
}
.topText {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 127%;
  text-align: center;
  position: relative;
  color: #212121;
}

.topText::after {
  position: absolute;
  bottom: 13px;
  left: 50%;
  transform: translateX(-50%);
  content: '';
  border-top: 2px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(132.33deg, #d24074 -0.67%, #6518b4 102.54%);
  width: 37px;
}
.hintText {
  color: #989898;
  font-size: 14px;
  width: 100%;
  text-align: center;
}
.rules {
  border: 0.5px solid;

  border-image-source: linear-gradient(132.33deg, #d24074 -0.67%, #6518b4 102.54%);

  border-image-slice: 1;
  border-radius: 2px;
  height: fit-content;
  padding: 3.5px 6.5px;
  cursor: pointer;
  margin-right: 10px;
  justify-self: end;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 127%;

  text-align: center;

  background: linear-gradient(132.33deg, #d24074 -0.67%, #6518b4 102.54%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.hintText1 {
  color: #989898;
  font-size: 14px;
  width: 100%;
  text-align: center;
}
.image {
  width: 61px;
  height: 61px;
  margin: auto;
  cursor: pointer;
}
.quizHeading {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 115.5%;
  /* identical to box height, or 28px */

  /* black */

  color: #212121;
}
.quizViewBtn {
  background: linear-gradient(132.33deg, #d24074 -0.67%, #6518b4 102.54%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  border: unset;
  line-height: 115.5%;
}
.imageWeb {
  width: 71px;
  height: 71px;
  cursor: pointer;
  margin-bottom: 16px;
}
.total_score {
  display: grid;
  justify-content: center;
  border: 1px solid #1da1f2;
  padding: 5px;
  font-size: 12px;
  width: 100px;
  margin-right: 10px;
  height: 50px;
}
.mobile_total_score {
  display: flex;
  justify-content: center;
  border: 1px solid #1da1f2;
  padding: 5px;
  font-size: 12px;
  margin-right: 15px;
  border-radius: 10px;
  margin-bottom: 20px;
}
.marginRight {
  margin-right: 10px;
}

.quizCardGrid {
  /* display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch; */
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 5px 20px;
  padding: 17px 36px;
  background: #ffffff;
  border: 1px solid #fff7f7;
  box-sizing: border-box;
  box-shadow: 0px 4px 8px -4px rgba(22, 34, 51, 0.08), 0px 16px 24px rgba(22, 34, 51, 0.08);
  border-radius: 6px;
  margin: 0 20px;
}
.quizCardGridWeb {
  /* display: flex;
  flex-wrap: wrap; */
  justify-content: center;
  align-items: stretch;
  /* gap: 5px 20px; */
  display: grid;
  grid-template-columns: repeat(4, 200px);
  grid-template-rows: repeat(2, 150px);

  grid-column-gap: 18px;
  grid-row-gap: 18px;

  padding: 17px 8px;

  margin: 20px 0 0 0;
  padding-bottom: 50px;
}
.pinkBG {
  background-color: #fff6f6;
  position: absolute;
  top: 20%;
  height: 80%;
  z-index: -1;
  width: 100%;
  /* height: 60vh; */
}

.mainCategory {
  background: #ffffff;
  border: 1px solid #fff7f7;
  box-sizing: border-box;
  box-shadow: 0px 4px 8px -4px rgba(22, 34, 51, 0.08), 0px 16px 24px rgba(22, 34, 51, 0.08);
  border-radius: 4px;
  width: 101px !important;

  height: 101px;
  padding: 9px 10px 13px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.mainCategoryWeb {
  cursor: pointer;
  background: #ffffff;
  border: 1px solid #fff7f7;
  box-sizing: border-box;
  box-shadow: 0px 4px 8px -4px rgba(22, 34, 51, 0.08), 0px 16px 24px rgba(22, 34, 51, 0.08);
  border-radius: 4px;
  width: 170px !important;
  height: 153px !important;
  padding: 9px 10px 13px;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  border-radius: 19.2308px;
}
.levelCategory {
  border: 1px solid #1fa1d2;
  display: grid;
  border-radius: 10px;
  width: 200px;
  padding: 20px;
  justify-content: center;
}

.category {
  border-radius: 10px;
  padding: 40px;
  cursor: pointer;
}
.categoryName {
  width: 100%;
  text-align: center;
  margin-top: 8px !important;
  font-size: 14px;
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 8px;
  line-height: 115.5%;
  text-align: center;
  color: #212121;
}
.categoryNameWeb {
  width: 100%;
  text-align: center;
  /* margin-top: 8px !important; */
  /* font-size: 13px; */
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 14px;
  line-height: 115.5%;
  text-align: center;
  color: #212121;
}
.lock {
  margin-top: 10px;
  border: 0px;
  background-color: #989898;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px;
  font-size: 12px;
  color: #fff;
  border-radius: 10px;
}
.play {
  margin-top: 10px;
  border: 0px;
  background-color: #1da1f2;
  padding: 3px;
  font-size: 12px;
  color: #fff;
  border-radius: 10px;
}
.levelName {
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 12px;
  line-height: 127%;
  background: linear-gradient(132.33deg, #d24074 -0.67%, #6518b4 102.54%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.levelNameLocked {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 127%;

  color: #878787;
}

.levelSubtext {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 127%;

  color: #878787;
}

.levelSubtextLocked {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 127%;
  color: #c4c4c4;
}

.width {
  width: 250px;
  display: grid;
  justify-content: center;
}
.header {
  background: #fdf9f6;
  color: #535353;
  border-radius: 25px;
  text-align: center;
  padding: 50px 20px;
  /* margin: 30px; */
  font-size: 0.9rem;
  margin-bottom: 30px;
}
.pHeading {
  font-size: 40px;
  margin-bottom: 5px;
  color: #000;
  text-transform: uppercase;
}

.head {
  font-size: 34px;
  display: inline-block;
  font-weight: 400 !important;
  /* border-bottom: 4px solid #84855d; */
  padding-bottom: 1rem;
  margin-bottom: 2rem;
}
.headCat {
  font-size: 16px;
  display: inline-block;
  font-weight: bold !important;
  border-bottom: 4px solid #84855d;
  padding-bottom: 1rem;
  margin-bottom: 2rem;
  margin-left: 55px;
}
.gap {
  padding: 0 150px;
}
.cards1 {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  margin-left: auto;
  margin-right: auto;
}
.card {
  width: 200px;
  height: 150px;
  background-color: #e7e2de;
  border-radius: 15px;
  margin: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 1rem;
}
.cardinside {
  height: 90%;
  width: 90%;
  background-color: #48483d;
  display: flex;
  background-color: #fdeddb;
  border-radius: 15px;
  align-items: center;
  padding: 10px;
  justify-content: center;
  flex-direction: column;
}

.name {
  width: 190px;
  /* height: 60px; */
  font-weight: normal;
  font-size: 12px;
  /* line-height: 28px; */
  text-align: center;
  /* margin-left: auto; */
  /* margin-right: auto; */
  margin-top: 10px;
}
.icon {
  height: 40px;
  width: 40px;
}
.box {
  display: flex;
  flex-direction: column;
  /* justify-content: space-around; */
  /* align-items: center; */
  /* height: 900px; */
  width: 600px;
  overflow-x: hidden;
  /* overflow-y: scroll; */
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
}
.box1 {
  display: flex;
  flex-direction: column;
  /* justify-content: space-around; */
  /* align-items: center; */
  /* height: 900px; */
  width: 70%;
  overflow-x: hidden;
  /* overflow-y: scroll; */
  min-height: 65vh;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
}
.quizBox {
  background-color: #fcfcfc;
  border: 1px solid #f5f5f5;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  position: relative;
}
.actionButton {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.quizQues {
  /* width: 100px; */
  height: fit-content;
  padding: 5px;
  border-radius: 15px;
  margin: 0px 5px;
  font-size: 12px;
  border: 1px solid #1da1f2;
}
.quizIndividualQues {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 127%;
  color: #212121;
}

.individualQuestion {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 127%;
  text-align: left;
  color: #212121;
  padding-bottom: 10px;
}

.quiz {
  display: flex;
  width: 600px;
  /* height: 180px; */
  background: #f0f0f0;
  box-shadow: -4px 4px 20px -6px #fdeddb;
  border-radius: 4px;
  margin: 5px 0;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
  border-radius: 4px;
  cursor: pointer;
}
.quiz1 {
  display: flex;
  width: 600px;
  cursor: pointer;
  /* height: 180px; */
  background: #fdf9f6;
  box-shadow: -4px 4px 20px -6px #fdeddb;
  border-radius: 4px;
  margin: 5px 0;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
  border-radius: 4px;
}
.subbox {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: center; */
  /* margin: 50px; */
  margin-left: 2rem;
}
.subbox1 {
  display: flex;
}
.playicon {
  /* margin: 80px; */
  /* margin-left: 200px; */
  margin-right: 3rem;
  transform: scale(2);
}
.hr1 {
  border: 3px solid #84855d;
  width: 80px;
}
.qno {
  width: 120px;
  height: 50px;
  font-size: 25px;
  background: #84855d;
  border-radius: 50px;
  color: #ffffff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.content {
  display: flex;
  justify-content: space-between;
  width: 800px;

  margin-top: 50px;
}
.sub1 {
  width: 540px;
  height: 100px;

  margin-right: 40px;
}
.quesc {
  width: 440px;
  background: #f0f0f0;
  box-shadow: -4px 4px 20px -6px #fdeddb;
  border-radius: 4px;
  font-size: 16px;
  margin-bottom: 20px;
  padding: 1rem;
}
.ques {
  padding-top: 10px;
  padding-left: 10px;
}
.btns {
  display: flex;
  justify-content: space-between;
}
.button {
  margin: 10px;
  padding: 5px 30px;
  font-size: 14px;
  background-color: #fff;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgb(0 0 0 / 0.2);
}
.retry {
  margin: 10px;
  padding: 5px 30px;
  font-size: 14px;
  background-color: #1fa1d2;
  border: 0px;
  border-radius: 5px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.exit {
  font-size: 16px;
  margin-right: 5px;
}
.options1 {
  display: flex;
  flex-direction: column;
  height: 600px;
  width: 300px;
  margin-left: 20px;
}
.option {
  margin-top: 10px !important;
  text-align: center;
  background: #ffffff;
  box-shadow: 0px 4px 8px -4px rgba(22, 34, 51, 0.15), 0px 16px 24px rgba(22, 34, 51, 0.08);
  border-radius: 5px;
  padding: 11px;
  cursor: pointer;
  border: double 1px transparent;
  background-image: linear-gradient(white, white), linear-gradient(to right, #d24074, #6518b4);
  background-origin: border-box;
  background-clip: padding-box, border-box;
}

.option > span {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 127%;
  background: linear-gradient(132.33deg, #d24074 -0.67%, #6518b4 102.54%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.sound {
  margin-top: 0;
  margin-left: 0;
  justify-self: end;
}
.modal1 {
  position: absolute;
  top: 100px;
  right: 200px;
}

.level {
  display: inline-block;
  padding: 0.5rem 0;
  border-bottom: 4px solid #84855d;
  width: max-content;
  font-size: 20px;
}
.questionNumber {
  padding: 0.5rem 1rem;
  background-color: #84855d;
  color: white;
  border-radius: 50px;
}
.quizHead {
  margin: 30px 0;
  display: flex;
  align-items: center;
}
.timer {
  display: flex;
  align-items: center;
  margin-left: 5rem;
  font-size: 1rem;
}
.logo {
  margin-right: 5px;
  height: fit-content;
  /* margin-top: -5px; */
}
.question {
  text-align: center;
  height: fit-content;
  margin-top: 10px;
  font-size: 14px;
}
.BuyHints {
  /* width: 100%;
  text-align: center; */
  color: var(--themeBlue);
  /* border-bottom: 1px solid #1fa1d2; */
}
.RuleDiv {
  padding: 3px 8px;
  cursor: pointer;
  background-color: transparent;
}
.BorderMain {
  border: double 1px transparent;
  border-radius: 4px;

  background-image: linear-gradient(white, white), linear-gradient(to right, #d24074, #6518b4);
  background-origin: border-box;
  background-clip: padding-box, border-box;
}
.HintSection {
  display: flex;
  /* background: transparent; */
  align-items: center;
  cursor: pointer;
  padding: 4px 12px;
}
.hinted {
  display: flex;
  justify-content: space-between;
  background: #ffffff;
  box-shadow: 0px 4px 8px -4px rgba(22, 34, 51, 0.08), 0px 16px 24px rgba(22, 34, 51, 0.08);
  border-radius: 4px;
  margin-top: 10px;
  width: 100%;
  padding: 2px;
}
.hintOption {
  display: flex;
  border-radius: 30px;
  align-items: center;
  gap: 1.5px;
  padding: 8px 0 8px 10px;
}

.hintOptionText {
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 14px;
  line-height: 127%;

  text-align: center;
  color: #212121;
}
.goToWallet {
  margin: 10px;
  padding: 5px 15px;
  font-size: 14px;
  background-color: var(--themeBlue);
  color: rgb(255, 255, 255);
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgb(0 0 0 / 0.2);
  border-radius: 8px;
}
.closeInsfBtn {
  margin: 10px;
  padding: 5px 15px;
  font-size: 14px;
  background-color: rgb(243, 239, 239);
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgb(0 0 0 / 0.2);
  border-radius: 8px;
}
.hintsModalDailogBox {
  margin: auto;
  margin-left: -100px;
}
.cancelButton {
  border: 0px;
  width: 100px;
  padding: 5px 0px;
  background-color: #ccc;
  margin: 0px 10px;
  border-radius: 15px;
}
.result {
  position: relative;
}
.textOnImage {
  position: absolute;
  width: 200px;
  padding: 5px 15px;
  font-weight: 600;
  border-radius: 15px;
  bottom: 60px;
  background-color: rgba(204, 204, 204, 0.788);
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
}

.resultImage {
  width: 100%;
  height: 100%;
}
.confirmButton {
  border: 0px;
  width: 100px;
  padding: 5px 0px;
  margin: 0px 10px;
  background-color: #1da1f2;
  color: #fff;
  border-radius: 15px;
}
.loadMore {
  padding: 5px 10px;
  border: 1px solid #1da1f2;
  color: #1fa1d2;
  background-color: #fff;
  margin-top: 20px;
  border-radius: 10px;
}
.small_info {
  font-size: 12px !important;
  text-align: center;
}
.buy {
  width: 81px;
  height: 100%;

  background: linear-gradient(132.33deg, #d24074 -0.67%, #6518b4 102.54%);
  box-shadow: 0px 4px 8px -4px rgba(22, 34, 51, 0.08), 0px 16px 24px rgba(22, 34, 51, 0.08);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: none;
}
.buy > span {
  gap: 1.5px !important;
}
.buy > span > span {
  font-size: 18px;
  line-height: 0;
}
.hints {
  width: 100%;
  padding: 15px;
  display: flex;
  justify-content: center;
}

.val {
  padding: 0.5rem 1rem;
  background-color: #84855d;
  color: white;
  border-radius: 15px;
}
.noOf {
  display: flex;
  align-items: center;
}
.noOf i {
  margin-right: 0.5rem;
}

.pad {
  width: 80%;
  margin: 0 auto;
}

.down {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 30px;
}
.downBtn {
  width: 200px;
}

/* ---------------------------------------- */
.mobilelevelCategory {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
  width: 100%;
  height: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 8px -4px rgba(22, 34, 51, 0.08), 0px 16px 24px rgba(22, 34, 51, 0.08);
  border-radius: 10px;
  min-height: 60px;
  padding-left: 15px;
}

.mobileimage {
  width: 45px;
  height: 41px;
  margin: auto;
  margin-right: 10px;
}
.mobileplay {
  border: 0px;
  margin: auto;
  margin-top: 10px;
  background-color: #1da1f2;
  font-size: 12px;
  color: #fff;
  border-radius: 50%;
  padding: 3px;
  align-items: center;
  max-width: 50px !important;
  max-height: 50px !important;
  margin-right: 10px;
}
.mobilelock {
  border: 0px;
  background-color: #989898;
  font-size: 12px;
  align-items: center;
  padding: 3px;
  color: #fff;
  border-radius: 50%;
  max-width: 50px !important;
  max-height: 50px !important;
  margin-right: 10px;
}

.cardGridWrapper {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  background-image: url(../../assets/quiz-home-background.svg);
  background-size: 100% 90%;
  background-repeat: no-repeat;
  margin: 16px auto 50px;
}

.hintFilledWrapper {
  background: linear-gradient(132.33deg, #d24074 -0.67%, #6518b4 102.54%);
  border: 0.5px solid #ffffff;
  box-sizing: border-box;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5.5px 10px;
}
.hintFilledWrapperWeb {
  background: linear-gradient(132.33deg, #d24074 -0.67%, #6518b4 102.54%);
  border: 0.5px solid #ffffff;
  box-sizing: border-box;
  border-radius: 5px;
  min-width: 55px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7.5px 12px;
  cursor: pointer;
}

.hintTextFilled {
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 10px;
  height: 13px;
  /* line-height: 127%; */
  text-align: center;
  color: #ffffff;
}
.hintTextFilledWeb {
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 15px;
  line-height: 127%;
  text-align: center;
  color: #ffffff;
}

.topHeading {
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 18px !important;
  line-height: 127%;
  text-align: center;
  color: #212121;
}

.topNavLevels {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  width: 100%;
}

.topCard {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 35px;
  padding: 14px 49px;
  background: #ffffff;
  border: 1px solid #fff7f7;
  box-sizing: border-box;
  box-shadow: 0px 4px 8px -4px rgba(22, 34, 51, 0.08), 0px 16px 24px rgba(22, 34, 51, 0.08);
  border-radius: 10px;
  margin: 0 20px 20px;
}
.topCardWeb {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 35px;
  width: 44%;
  padding: 30px 59px;
  background: #ffffff;
  border: 1px solid #fff7f7;
  box-sizing: border-box;
  box-shadow: 0px 4px 8px -4px rgba(22, 34, 51, 0.08), 0px 16px 24px rgba(22, 34, 51, 0.08);
  border-radius: 10px;
  margin: 0 20px 20px;
}
.CenterContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.topCardRight {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.WebHeader {
  width: 14%;
}
.topCardRightWeb {
  width: 82%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.topCardHeading {
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 12px;
  line-height: 115.5%;
  background: linear-gradient(132.33deg, #d24074 -0.67%, #6518b4 102.54%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  margin-bottom: 3px;
}
.topCardHeadingWeb {
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 26px;
  line-height: 115.5%;
  background: linear-gradient(132.33deg, #d24074 -0.67%, #6518b4 102.54%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  margin-bottom: 3px;
}
.topCardSubheading {
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 6px;
  line-height: 115.5%;
  color: #878787;
  margin-bottom: 11px;
}
.topCardSubheadingWeb {
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 11px;
  line-height: 115.5%;
  color: #878787;
  margin-bottom: 11px;
}

.quizLevelBgWrapper {
  width: 100%;
  height: 100%;
  background-image: url(../../assets/quiz-home-background.svg);
  background-size: 100% 90%;
  background-repeat: no-repeat;
}
.quizLevelBgWrapperWeb {
  width: 100%;
  height: 100%;
  display: flex;
  margin-top: 1.5%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-size: 100% 90%;
  background-repeat: no-repeat;
}

.quizLevelsBottomWrapper {
  background: #ffffff;
  border: 1px solid #fff7f7;
  box-sizing: border-box;
  box-shadow: 0px 4px 8px -4px rgba(22, 34, 51, 0.08), 0px 16px 24px rgba(22, 34, 51, 0.08);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 20px 20px;
  padding: 16px 15px 27px;
}
.quizLevelsBottomWrapperWeb {
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 20px 20px;
  padding: 16px 15px 27px;
}

.loadMoreBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90px;
  height: 33px;

  background: linear-gradient(132.33deg, #d24074 -0.67%, #6518b4 102.54%);
  border-radius: 3px;

  font-family: 'Poppins';
  font-weight: 600;
  font-size: 12px;
  line-height: 115.5%;
  color: #ffffff;
  outline: none;
  border: none;
  margin: 10px auto 40px;
}

.quizIndividualHeading {
  font-family: 'Poppins';
  font-weight: 700;
  font-size: 18px;
  line-height: 127%;
  text-align: center;
  color: #212121;
  margin-top: 30px;
}

.quizIndividualBackgroundWrapper {
  background: url(../../assets/quiz-individual-bg.svg);
  background-repeat: no-repeat;
  background-size: 100% 500px;
  background-position: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-top: -40px;
}
.quizIndividualBackgroundWrapperWeb {
  background: url(../../assets/quiz-individual-bg.svg);
  background-repeat: no-repeat;
  background-size: 1100px;
  background-position: center;
  background-position: inherit;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 75%;
  margin-top: -40px;
}

.quizIndividualCardWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding: 21px 50px 28px;
  background: #ffffff;
  border: 1px solid #fff7f7;
  box-sizing: border-box;
  box-shadow: 0px 4px 8px -4px rgba(22, 34, 51, 0.08), 0px 16px 24px rgba(22, 34, 51, 0.08);
  border-radius: 6px;
  margin: 0 20px 20px;
}
.quizIndividualCardWrapperWeb {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 606px;
  padding: 21px 50px 28px;
  background: #ffffff;
  border: 1px solid #fff7f7;
  box-sizing: border-box;
  box-shadow: 0px 4px 8px -4px rgba(22, 34, 51, 0.08), 0px 16px 24px rgba(22, 34, 51, 0.08);
  border-radius: 6px;
  margin: 0 20px 20px;
}

.confirmIKCPurchaseTitle {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 115.5%;
  text-align: center;

  background: linear-gradient(132.33deg, #d24074 -0.67%, #6518b4 102.54%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  margin-top: 5px;
  margin-bottom: 20px;
}

.confirmIKCPurchaseSubtitle {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 115.5%;
  text-align: center;

  color: #212121;

  margin-bottom: 25px;
}

.hintModalAmountText {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 115.5%;

  color: #ffffff;
}

.hintModalHeader {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-bottom: 15px;
}

.hintModalHeaderLeft {
  display: flex;
  flex-direction: column;
}

.hintModalHeaderRight {
  display: flex;
  align-items: center;
  gap: 0;
}

.hintModalHeaderLeftTitle {
  font-family: 'Poppins';

  font-weight: 700;
  font-size: 14px;
  line-height: 127%;

  color: #212121;
}

.hintModalHeaderLeftSubtitle {
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 10px;
  line-height: 127%;

  color: #878787;
}

.hintModalHeaderRightText {
  font-family: 'Poppins';
  font-weight: 700;
  font-size: 25.0908px;
  line-height: 115.5%;

  color: #212121;
}

@media (max-width: 750px) {
  .marginLeft {
    margin-left: 0px;
  }
  .hintOption {
    width: 150px;
  }
  .resultImage {
    /* width: 300px; */
    height: 300px;
  }
  .chooseCat.width {
    width: 71% !important;
  }
  .chooseCat.topNav {
    justify-content: end;
  }
}
@media (max-width: 650px) {
  .mobileplay {
    margin-top: 0 !important;
  }
  .closeInsfBtn,
  .goToWallet {
    font-size: 12px;
    padding: 10px;
    border-radius: 5px;
  }
  .quizbanner {
    max-height: 90% !important;
    border-radius: 10px;
    margin-bottom: 0px;
    max-width: 80%;
    box-shadow: none;
  }

  .hint {
    justify-content: center;
    border-radius: 10px;
    cursor: pointer;
    padding: 3px 5px !important;
    width: 30px;
    margin-left: 10px;
    height: 30px;
  }
  .hintDB {
    opacity: 1;
    transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    width: 70%;
  }
  .hintDBWeb {
    opacity: 1;
    display: none;
    transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    width: 70%;
  }
  .BOX1.hint {
    width: 100px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    justify-self: start;
  }
}

@media screen and (max-width: 500px) {
  .button {
    font-size: 12px !important;
    margin: 5px;
    padding: 5px 10px;
  }

  .box1 {
    width: 100%;
    transform: translateY(-40px);
  }
  .question {
    font-size: 12px !important;
  }
}
@media screen and (max-width: 350px) {
  .chooseCat.topNav {
    justify-content: end;
  }
}

.ResultContainer {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.StatusContainer {
  position: relative;
  background-color: #fff;
  background: #ffffff;
  border: 1.73751px solid #fff7f7;
  box-shadow: 0px 6.95004px 13.9001px -6.95004px rgba(22, 34, 51, 0.08), 0px 27.8002px 41.7002px rgba(22, 34, 51, 0.08);
  border-radius: 10.4251px;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 27%;
  height: 30%;
  padding: 30px 20px;
}

.BottomWrapper {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 80%;
}

.BottomBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border: 1.73751px solid #fff7f7;
  box-shadow: 0px 6.95004px 13.9001px -6.95004px rgba(22, 34, 51, 0.08), 0px 27.8002px 41.7002px rgba(22, 34, 51, 0.08);
  border-radius: 17.3751px;
  width: 150px;
  height: 133px;
}
.pinkSmallBG {
  background: #fff6f6;
  width: 60%;
  height: 40%;
  position: absolute;
  top: 18.6%;
  z-index: -1s;
}
.slick-arrow {
}
.slick-next:before,
.slick-prev:before {
  background: linear-gradient(132.33deg, #d24074 -0.67%, #6518b4 102.54%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  width: 40px;
  font-size: 24px;
  text-fill-color: transparent;
}
.ExitTryWrapper {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 17%;
  margin-top: 30px;
  margin-bottom: 2%;
}
.ExitTryBox {
  cursor: pointer;
  background: linear-gradient(132.33deg, #d24074 -0.67%, #6518b4 102.54%);
  /* -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  
  text-fill-color: transparent; */
  color: #fff;
  padding: 6px 22px;
  min-width: 110px;
  display: flex;
  min-height: 42px;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}
.ResultsScore {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  margin-top: 5cpx;
  line-height: 115.5%;
  /* identical to box height, or 28px */

  /* black */

  color: #212121;
}
.BottomBoxTitle {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 20px;
  line-height: 115.5%;
  text-align: center;
  /* or 16px */

  /* black */

  color: #212121;
}
.BottomStats {
  background: linear-gradient(132.33deg, #d24074 -0.67%, #6518b4 102.54%);
  /* -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  
  text-fill-color: transparent; */
  color: #fff;
  padding: 6px 22px;
  border-radius: 6px;
}

.WebWrapper {
  display: grid;
  grid-template-columns: repeat(5, 170px);
  grid-template-rows: repeat(2, 150px);

  grid-column-gap: 20px;
  grid-row-gap: 14px;
}
.Webimage {
  width: 68px;
  height: 65px;
  margin-top: 10px;
  /* margin: auto; */
  /* margin-right: 10px; */
}
.BottomNav {
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
}
.BottomLeft {
  width: 80%;
  padding: 2px 0px 12px 12px;
}
.BottomTitle {
  margin: unset !important;
  font-size: 20px;
  font-weight: 600;
  background: linear-gradient(132.33deg, #d24074 -0.67%, #6518b4 102.54%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  color: linear-gradient(132.33deg, #d24074 -0.67%, #6518b4 102.54%);
}
.BottomTitleRes {
  margin: unset !important;
  font-size: 24px;
  font-weight: 600;
  background: linear-gradient(132.33deg, #d24074 -0.67%, #6518b4 102.54%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  color: linear-gradient(132.33deg, #d24074 -0.67%, #6518b4 102.54%);
}
.BottomSubTitle {
  font-size: 11px;
  color: '#878787';
}
.BottomRight {
  height: 100%;
  width: 30%;
}
.WebContainer {
  cursor: pointer;
  border: 1.12371px solid #fff7f7;
  box-shadow: 0px 4.49485px 8.98969px -4.49485px rgba(22, 34, 51, 0.08), 0px 17.9794px 26.9691px rgba(22, 34, 51, 0.08);
  border-radius: 4.49485px;
  background-color: #fff;
  display: flex;
  border-radius: 6px;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}
