.hideat900 {
  display: flex;
  justify-content: space-between !important;
}

.user-profileInfo {
  flex: 1;
  margin-bottom: 10px;
}
.user-profileInfo p {
  margin: 0px auto;
}
.user-profileInfo p:nth-child(1) {
  font-size: 15px;
}
.navbar_logo {
  width: 22px !important;
  margin-right: 10px !important;
  /* fill:var(--themeBlue); */
  /* color: var(--themeBlue) !important; */
}
.showatt900 {
  display: none !important;
}
/* .height900 { */
/* height: 150px; */
/* } */

.dropbtn {
  /* background-color: #04aa6d; */
  color: white;
  /* padding: 16px; */
  font-size: 16px;
  border: none;
}
.avatar {
  align-self: flex-start;
  height: 36px;
  width: 36px;
  /* border-radius: 15px; */
  border-radius: 50%;
  margin-right: 10px;
}
.navText1 {
  color: black;
  /* border-right: 1px solid #ccc; */
  padding-right: 30px;
  padding-left: 20px;
  cursor: pointer;
}

.navText1:last-child {
  border-right: none !important;
}

@media only screen and (min-width: 1440px) {
  .navText1 {
    padding: 2.5rem;
  }
}

.dropdown {
  position: relative;
  display: inline-block;
}
.ant-dropdown {
  width: fit-content;
  position: absolute !important;
  right: 20px;
  left: unset !important;
  top: 68px !important;
}
.ant-dropdown-menu {
  border-radius: 5px;
  /* padding-left: unset !important; */
  list-style: none !important;
  background-color: #fff;
  padding: 0.75rem;
}
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #ffffff;
  /* min-width: 160px; */
  min-width: 750px;
  border-radius: 2px;
  /* margin-top: 5px; */
  border: 1px solid #f1f1f1;
  z-index: 1;
  display: none !important;
}

.dropdown-content a {
  color: #949494;
  padding: 0px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  /* background-color: #f5f5f5; */
  color: #00deff;
}

.dropdown:hover .dropdown-content {
  display: block !important;
}
.dropdown-content:hover {
  display: block !important;
}
.dropdown-content2 {
  display: none;
  position: absolute;
  background-color: #ffffff;
  min-width: 200px;
  /* min-width: 750px; */
  border-radius: 2px;
  /* margin-top: 5px; */
  border: 1px solid #f1f1f1;
  z-index: 1;
  display: none !important;
}

.dropdown-content2 a {
  color: #949494;
  padding: 5px 16px;

  text-decoration: none;
  display: block;
}

.dropdown-content2 a:hover {
  /* background-color: #f5f5f5; */
  color: #00deff;
}

.dropdown:hover .dropdown-content2 {
  display: block !important;
}
.dropdown-content2:hover {
  display: block !important;
}

.dropdown-content1 {
  display: none;
  position: absolute;
  background-color: #ffffff;
  min-width: 200px;
  /* min-width: 750px; */
  border-radius: 2px;
  /* margin-top: 5px; */
  border: 1px solid #f1f1f1;
  z-index: 1;
  display: none !important;
}

.dropdown-content1 a {
  color: #8a8a8a;
  padding: 0px 16px;
  text-decoration: none;
  font-family: PT SANS;
  display: block;
}

.dropdown-content1 a:hover {
  /* background-color: #f5f5f5; */
  color: #00deff;
}
.dropdown:hover .dropdown-content1 {
  display: block !important;
}
.dropdown-content1:hover {
  display: block !important;
}

.dropdown:hover .dropbtn {
  /* background-color: #3e8e41; */
}
.lessthan1010 {
  display: none !important;
}

.morethan1010 {
  display: block !important;
}

.morethan912 {
  display: block !important;
}
.lessthan912 {
  display: none !important;
}

@media screen and (max-width: 912) {
  .morethan912 {
    display: none !important;
  }
  .lessthan912 {
    display: none !important;
  }
}

@media screen and (max-width: 1262px) and (min-width: 1200px) {
  .lessthan1010 {
    display: block !important;
  }
  .morethan1010 {
    display: none !important;
  }
}

@media screen and (max-width: 1010px) {
  .lessthan1010 {
    display: block !important;
  }
  .morethan1010 {
    display: none !important;
  }
}
@media (max-width: 900px) {
  .height900 {
    height: 80px !important;
  }

  .hideat900 {
    display: none !important;
  }
  .showatt900 {
    display: flex !important;
  }
  .overflowat900 {
    overflow-x: auto !important;
    flex-wrap: nowrap !important;
  }
  .dropdown-content {
    display: none !important;
  }
}
