@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins';
}

a,
a:hover,
a:visited,
a:focus {
  text-decoration: none;
  color: inherit;
}

p {
  margin: 0;
  margin-bottom: 0;
  padding: 0;
}
:root {
  --themeBlue: #1da1f2;
}
.main-view-container {
  margin-top: 15px;
  min-height: 80vh;
  -moz-transform: scale(0.8, 0.8);
  zoom: 0.8;
  zoom: 80%;
  /* padding: 0px 150px; */
}
body {
  background: #fff7f7;
  font-family: 'Poppins';
  /* overflow-x: hidden; */
}
button,
i {
  cursor: pointer;
  outline: none;
}
input {
  outline: none;
}
.container {
  width: 90%;
  margin: auto;
}

.search-wrapper .chip {
  background: #48483d;
}
#root {
  /* overflow-x: hidden; */
  height: 100vh;
}
/* 
select,
select:focus,
input[type='text'],
input[type='number'],
textarea {
  font-size: 16px;
} */

@media screen and (max-width: 900px) {
  .main-view-container {
    -moz-transform: scale(0.8, 0.8);
    zoom: 0.8;
    zoom: 80%;
    padding: 0px 50px;
  }
}
@media screen and (max-width: 480px) {
  .main-view-container {
    -moz-transform: scale(1, 1);
    zoom: 1;
    zoom: 100%;
    padding: 0px;
  }
}
@media screen and (min-width: 2400px) {
  .container {
    width: 50%;
  }
  .main-view-container {
    -moz-transform: scale(1.1, 1.1);
    zoom: 1.1;
    zoom: 110%;
    padding: 10px 350px;
  }
}

.react-multi-carousel-list {
  position: static !important;
}

*:focus-visible {
  outline: none;
}
/* li:hover,
a:hover,
p:hover,
h1:hover,
h2:hover,
h3:hover,
h4:hover,
h5:hover,
h6:hover {
  color: #1da1f2;
} */
