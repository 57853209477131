.container {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  padding: 60px 20px 30px 20px;
  width: 500px;
  margin: auto;
  max-height: 1380px;
  overflow-y: scroll;
}
.container::-webkit-scrollbar {
  width: 0;
  background: transparent;
}
.mobileContainer {
  background-color: unset !important;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  padding: 0 20px 30px 20px;
  /* margin-top: 80px; */
  background: #fff;
  max-height: 95%;
  overflow-y: scroll;
  margin-top: 80px;
  width: 100%;
}
.inputWrapper {
  position: relative;
  width: 100%;
}
.inputFieldImage {
  cursor: pointer;
  position: absolute;
  z-index: 3;
  right: 20px;
  top: 50%;
}
.inputField {
  background: #efefef;
  border-radius: 8px;
  width: 100%;
  font-size: 12px;
  font-weight: 400;
  line-height: 110.5%;
  margin-bottom: 15px;
  height: 45px;
  border: none;
  outline: none;
  padding: 16px 20px;
  font-family: Poppins;
  font-style: normal;
}
.noOfResults {
  margin-bottom: 16px;
}
.cardWrapper {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: start;
  align-items: flex-start;
  gap: 10px;
  background: white;
  border: 1px solid #fff7f7;
  box-sizing: border-box;
  box-shadow: 2px 4px 8px -4px rgba(108, 96, 255, 0.08), 0px 16px 24px rgba(207, 158, 255, 0.08);
  border-radius: 5px;
  margin-bottom: 15px;
  padding: 15px 13px;
}
.cardTitle {
  color: #000;
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 10px;
  line-height: 127%;
  text-transform: capitalize;
}
.listDeck {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  overflow: hidden;
}
.listItem {
  position: relative;
  background: #fff;
  border: 0.5px solid #b7b7b7;
  box-sizing: border-box;
  border-radius: 4px;
  display: -webkit-box;
  display: flex;
  gap: 4px;
  min-height: 70px;
  padding: 5px;
  width: 100%;
}
.listItemImage {
  border-radius: 4px;
  -o-object-fit: cover;
  object-fit: cover;
  min-width: 63px;
  min-height: 37px;
  max-width: 63px;
}
.itemTextWrapper {
  margin: 0 4px 0 7px;
  align-self: center;
  color: #000;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: start;
  justify-content: flex-start;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  width: 70%;
}
.itemTitle {
  color: #000;
  font-weight: 500;
  font-size: 12px;
  align-self: flex-start;
  margin-bottom: 3px;
}
.itemSubtitle {
  color: #000;
  font-weight: 300;
  font-size: 10px;
}
.itemCta {
  padding: 5px;
  width: 50px;
  min-width: 38px;
  border-radius: 3px;
  -webkit-box-align: center;
  align-items: center;
  background: linear-gradient(132.33deg, #d24074 -0.67%, #6518b4 102.54%);
  color: #fff;
  text-transform: none;
  font-weight: 600;
  font-size: 9px;
  line-height: 115.5%;
  border: none;
  outline: none;
  align-self: flex-end;
  display: grid;
  place-items: center;
}
.more {
  background: linear-gradient(132.33deg, #d24074 -0.67%, #6518b4 102.54%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-weight: 500;
  line-height: 127%;
  align-self: flex-end;
  cursor: pointer;
}
