.center {
  margin: auto 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: white;
}
.otp-modal-container {
  background-color: white !important;
}
.inp {
  width: 80%;
  padding: 0.5rem 1rem;
  outline: none;
  border: none;
  border-bottom: 2px solid #d6d3d3;
  transition: 400ms ease;
}
.inp:hover,
.inp:active {
  border-bottom: 2px solid var(--themeBlue);
}
.inp:not(:last-of-type) {
  margin-bottom: 10px;
}
.google {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  border: 2px solid grey;
  border-radius: 10px;
  cursor: pointer;
}
.google img {
  margin-right: 1rem;
}
.otpBtn {
  padding: 0.5rem 1rem;
  border: none;
  outline: none;
  background-color: var(--themeBlue) !important;
  background-color: #1da1f2 !important;
  color: white;
  border-radius: 10px;
}
.errormssg {
  align-self: flex-start;
  font-weight: 300;
  color: red;
  font-size: 1.8vh;
  margin-left: 10%;
}
.OtpBox {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 31px;
}
.valid {
  outline: none !important;
  width: 45px !important;
  height: 45px !important;
  border-radius: 5px !important;
  border: double 1px transparent !important;

  background-image: linear-gradient(white, white), linear-gradient(to right, #d24074, #6518b4) !important;
  background-origin: border-box !important;
  background-clip: padding-box, border-box !important;
}
.LinearText {
  background: linear-gradient(132.33deg, #d24074 -0.67%, #6518b4 102.54%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  color: mediumvioletred;
  /* color:linear-gradient(132.33deg, #D24074 -0.67%, #6518B4 102.54%); */
}
.invalid {
  width: 45px !important;
  height: 45px !important;

  border-radius: 5px !important;

  border: double 1px transparent !important;
  background-image: linear-gradient(white, white), linear-gradient(to right, red, red) !important;
  background-origin: border-box !important;
  background-clip: padding-box, border-box !important;
  outline: none;
}
.container {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 12px;
}

.terms {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 12px;
  gap: 10px;
  margin: 30px 2px 25px 2px;
}

.terms i {
  color: green;
  font-size: 1.2rem;
  margin-right: 10px;
}
.cond {
  color: #1da1f2;
}

.cond > a {
  text-decoration: none;
}

.pleaseEnterOTP {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 127%;
  color: #212121;
  position: relative;
  margin-bottom: 15px;
}

.pleaseEnterOTP::before {
  content: '';
  border-top: 1.5px solid;
  border-image-source: linear-gradient(132.33deg, #d24074 -0.67%, #6518b4 102.54%);
  border-image-slice: 1;
  width: 55px;
  height: 0px;
  position: absolute;
  left: 50%;
  top: -10px;
  transform: translateX(-50%);
}

.invalidOTPText {
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 14px;
  line-height: 127%;
  color: #f00;
  position: relative;
  margin: 0;
}

.policyQuote {
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 11px;
  line-height: 127%;
  color: #212121;
  margin: 0;
}

.twoInputWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
}
